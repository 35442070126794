import { observable } from 'mobx'
import RootStore from './Root'
import { DeedResearchProps, ValueLabel } from './types.d'
import { getDeeds, editCompletedFlagInDeed, editPendingFlagInDeed, editWorkingFlagInDeed, editReviewedFlagInDeed, editDraftedFlagInDeed, getUsersEmailForDeedResearch } from '../api/DeedActions'
import { initialTableDataWithPaginationDetails, initialDeeds } from './InitialValues'

interface DeedsData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<DeedResearchProps>
}

class DeedStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public deedsDetails: DeedResearchProps
    @observable public deedsData: DeedsData
    @observable public searchItem: string
    @observable public searchByStatus: Array<ValueLabel>
    @observable public page: number
    @observable public filterWithFutureSigning: boolean
    @observable public tableRows: Array<DeedResearchProps>
    @observable public isTableView: string
    @observable public userId: string
    @observable public allUserEmail: any
    @observable public userEmailWithValueLabel: Array<ValueLabel>

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.searchItem = ''
        this.filterWithFutureSigning = true
        this.page = 1
        this.searchByStatus = []
        this.deedsDetails = initialDeeds
        this.fetchDeeds(1, '', '', true, [])
        this.deedsData = initialTableDataWithPaginationDetails
        this.tableRows = []
        this.getTableView()
        this.allUserEmail = []
        this.userEmailWithValueLabel = []
    }

    fetchUserEmail =  () => {
        this.isApiError = false
        this.apiErrorMessage = ''

        getUsersEmailForDeedResearch().then((response) => {
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.allUserEmail = response.data.data
            this.userEmailWithValueLabel = this.allUserEmail.map((list: any) => {
                const data = {
                    value: list._id,
                    label: list.email
                }
                return data
            })
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    getTableView = () => {
        const view = localStorage.getItem('view')
        if(view === null) {
            this.isTableView = 'table_view'
            localStorage.setItem('view', 'table_view')
        } else {
            this.isTableView = view
        }
    }

    setIsTableView = (value: string) => {
        this.isTableView = value
        localStorage.setItem('view', value)
    }
    //get the list of deeds with details
    fetchDeeds = (page: number, searchItem: string, userId: string, filterWithFutureSigning: boolean, searchByStatus: Array<ValueLabel>): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        let requestData ={}
        this.userId = userId
        if(userId === 'All') {
            this.userId = ''
        }

        for(let i = 0; i< searchByStatus.length; i++) {
           
            if(searchByStatus[i].value.includes('not')) {
                requestData[searchByStatus[i].value.replace('_not', '')] = false
            } else {
                requestData[searchByStatus[i].value] = true
            }
            
        }

        requestData = { ...requestData, page: page, search_item: searchItem, filterWithUserId: this.userId, is_future_signing: filterWithFutureSigning }
        this.page = page
        this.searchItem = searchItem
        this.filterWithFutureSigning = filterWithFutureSigning
        this.searchByStatus = searchByStatus

        getDeeds(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.deedsData = response.data.data
            this.tableRows = response.data.data.page_items
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markCompleted(matterId: string, isCompleted: boolean) {
        const data = { _id: matterId, is_completed: isCompleted } 
        this.isLoading = true
        editCompletedFlagInDeed(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.userId, this.filterWithFutureSigning, this.searchByStatus)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markDrafted(matterId: string, isDrafted: boolean, draftedNotes: string) {
        const data = { _id: matterId, is_drafted: isDrafted, drafted_notes: draftedNotes } 
        this.isLoading = true
        editDraftedFlagInDeed(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.userId, this.filterWithFutureSigning, this.searchByStatus)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markWorking(matterId: string, isWorking: boolean) {
        const data = { _id: matterId, is_working: isWorking } 
        this.isLoading = true
        editWorkingFlagInDeed(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.userId, this.filterWithFutureSigning, this.searchByStatus)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markPending(matterId: string, isPending: boolean, pendingNotes: string) {
        const data = { _id: matterId, is_pending: isPending, pending_notes: pendingNotes } 
        this.isLoading = true
        editPendingFlagInDeed(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.userId, this.filterWithFutureSigning, this.searchByStatus)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    markReviewed(matterId: string, isReviewed: boolean, reviewedNotes: string) {
        const data = { _id: matterId, is_reviewed: isReviewed, reviewed_notes: reviewedNotes } 
        this.isLoading = true
        editReviewedFlagInDeed(data).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.fetchDeeds(this.page, this.searchItem, this.userId, this.filterWithFutureSigning, this.searchByStatus)
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default DeedStore
