import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ArticleStore from '../../store/Article'
import SectionStore from '../../store/Section'
import SingleTemplateStore from '../../store/SingleTemplateStore'
import { ValueLabel } from '../../store/types.d'
import TimeAgo from 'react-timeago'
import { englishStrings } from '../lawmatics/EnglishString'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { Cell } from '../table/TableCellText.d'
import _ from 'lodash'
import ReactSelectComponent from './ReactSelectComponent'
import { RootContext } from '../../Contexts'
import uuid from 'react-uuid'

export const makeValueLabelList = (data: any, allOptions: Array<ValueLabel>) => {
    const returnData:Array<ValueLabel> = []
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < allOptions.length; j++) {
            if (data[i].toString() === allOptions[j].value.toString()) {
                returnData.push(allOptions[j])
            }
        }
    }
    return returnData
}

export function replaceSpaceWithHyphen(name: string): string {
    return name.replace(/ /g, '-')
}

interface DeleteButtonProps {
  targetStore: SingleTemplateStore | ArticleStore | SectionStore | undefined,
  removeItem: any,
  onClick: (store: any, removeItem: any, openClose: () => void) => void,
  openCloseModal: () => void
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ targetStore, removeItem, onClick, openCloseModal }) => {
    return <Link
        className='text-danger'
        to='#'
        onClick={() => {
            onClick(targetStore, removeItem, openCloseModal)
        }} > Delete
    </Link>
}

export const handleOnDelete = (store: SingleTemplateStore | ArticleStore | SectionStore, removeItem: any, openClose: () => void) => {
    const message = `Are you sure you want to delete this ${removeItem.type} which name is ${removeItem.name}`
    if (window.confirm(message)) {
        store.remove(removeItem)
    }
    openClose()
}

export interface ClientInfo {
  title: string,
  value?: string,
  diffValue?: string
  type?: string
  helpText?: string
}

export interface RequestData {
    title: string,
    value: any
}

export const ShowDetails: React.FC<ClientInfo> = ({ title, value, helpText = '' }) => {
    return <div className='row row-hover'>
        <div className='col-sm-4'>
            <b>{title} </b>
            { helpText ? <small className='form-text text-muted mt-0'>{ helpText }</small> : null}
        </div> :
        <div className='col'>{value}</div>
    </div>
}

export const ShowVisionDetails: React.FC<ClientInfo> = ({ title, value, type = 'String' }) => {
    return <div className='row'>
        <div className='col-sm-4'><b>{title} </b></div> :
        <div className='col'>{ type === 'Image' ? <img src={value} height={80} width={200} style={{ objectFit: 'contain' }}></img> : value }</div>
    </div>
}

export const dateFormat = (ISOdate) => {
    const date = new Date(ISOdate).toISOString().split('T')
    const formatDate = date[0].split('-')
    const returnDate = formatDate[1] + '/' + formatDate[2] + '/' + formatDate[0]
    return returnDate
}

export const FormateDate: React.FC<Cell> = (props) => {
    const formatter = buildFormatter(englishStrings)
    const newDate = props.value.split('T')
    const formatDate = newDate[0].split('-')
    const dateString = formatDate[1] + '/' + formatDate[2] + '/' + formatDate[0]
    const getTime = newDate[1].split('.')

    return (
        <>
            {dateString + ' ' + getTime[0]}<br/><TimeAgo date={props.value} formatter={formatter}></TimeAgo>
        </>
    )
}

export const isValueEquivalent = (a: any, b: any) => {
    if (!_.isEqual(a, b)) {
        return false
    }
    return true
}

export const TableCellTags: React.FC<Cell> = (props) => {
    const { value = [], data = {} } = props
    const rootStore = useContext(RootContext)
    const { tagList, updateTag } = rootStore.tagStore
    const [openEditor, setOpenEditor] = useState(false)
    const [ tag, setTag ] = useState(value)
    const [selectedOptionOfTag, setSelectedOptionOfTag] = useState<Array<ValueLabel>>()

    const option = tagList.map((list: any) => {
        const data = {
            value: list.tag || '',
            label: list.tag || ''
        }
        return data
    })
    const selectedTag = makeValueLabelList(tag, option)    

    useEffect(() => {
        if (selectedTag !== undefined) {
            setSelectedOptionOfTag(selectedTag)
        } else {
            setSelectedOptionOfTag([])
        }
    }, [setSelectedOptionOfTag])

    const handleOnFieldTagChange = (data: any) => {
        if (data !== null) {
            setSelectedOptionOfTag(data)
        }
    }

    const saveChanges = () => {
        const tagArray = selectedOptionOfTag?.map(item => item.value) || []
        
        let entity_name = 'Default'
        if (data.source) {
            entity_name = 'Client'
        } else if (data.client_name) {
            entity_name = 'Vision'
        } else if (data.name) {
            entity_name = 'Matter'
        }

        updateTag(entity_name, data._id, tagArray)
        setTag(tagArray)
        setOpenEditor(!openEditor)
    }

    const resetChange = () => {
        setSelectedOptionOfTag(selectedTag)
        setOpenEditor(!openEditor)
    }

    return (
        <>
            {openEditor ? 
                <div className='d-flex align-items-center'>
                    <ReactSelectComponent
                        options={option}
                        value={selectedOptionOfTag || []}
                        isMulti={true}
                        handleOnChangeReact={handleOnFieldTagChange}
                        isTag={true}
                    />
                    <div className='ml-auto'>
                        <button 
                            type='button' 
                            className='btn btn-outline-primary small-button' 
                            onClick={saveChanges}
                        >Save</button>

                        <button 
                            type='button' 
                            className='btn btn-outline-danger small-button ml-2' 
                            onClick={resetChange}
                        >Cancel</button>
                    </div>
                </div> : 
                <div className='d-flex'>
                    <span key={uuid()} className='mr-2'>{_.join(tag, ', ')}</span>
                    <button 
                        type='button' 
                        className='btn btn-outline-primary small-button ml-auto' 
                        onClick={() => setOpenEditor(!openEditor)}
                    >
                       Edit
                    </button>
                </div>
            }
        </>
    )
}