import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../../layout/Container'
import { useHistory } from 'react-router-dom'
import InputField, { InputFieldForVisionPlan } from '../../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../../store/types.d'
import classNames from 'classnames'
import DescriptionInfoStore from '../../../store/vision/DescriptionInfo'
import { initialDescriptionInfo } from '../../../store/InitialValues'
import Table from '../../table/Table'
import { DeleteDescriptionInfo, EditDescriptionInfo } from '../../commonComponent/EditDeleteLabelDecorationCellComponent'
import { descriptionInfoType, imageTypeText, noteScreenType } from '../../field/TypeList'
import DescriptionInfoModel from './DescriptionInfoModel'
import { Cell } from '../../table/TableCellText.d'
import _ from 'lodash'
import ReactSelectComponent from '../../commonComponent/ReactSelectComponent'

const optionForDescriptionInfoType = descriptionInfoType.map((list: any) => {
    const data = {
        value: list.value || '',
        label: list.label || ''
    }
    return data
})

const TableCellDescriptionType: React.FC<Cell> = (props) => {
    let value = props.value || ''
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    const type = _.find(descriptionInfoType, { value: value })
    return (
        <>
            {type?.label}
        </>
    )
}

const TableCellImageType: React.FC<Cell> = (props) => {
    let value = props.value || ''
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    
    const type = _.find(imageTypeText, { value: value })
    return (
        <>
            {type?.label}
        </>
    )
}

const initialValueLabel = { 
    value: 'note_screen', 
    label: 'Note' 
}

const AddUpdatePlanPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { planStore } = rootStore
    const { isApiError, isLoading, apiErrorMessage, planDetails, setPlanDetails, addUpdateSinglePlan, resetPlanDetails } = planStore

    const [editButton, setEditButton] = useState(false)
    const [isVisible, setIsVisible] = useState(true)
    const [isEnabled, setIsEnabled] = useState(true)

    const [showDescriptionInfoModel, setShowDescriptionInfoModel] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [descriptionInfo, setDescriptionInfo] = useState<DescriptionInfoStore>()

    const [selectedOptionOfNoteType, setSelectedOptionOfNoteType] = useState(initialValueLabel)
    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add Plan' : 'Update Plan'
    const pathArrayWithPluralId = history.location.pathname.split('/')

    const planId = (pageName === 'Update Plan') ? pathArrayWithPluralId[3] : ''

    useEffect(() => {
        if (pageName === 'Update Plan') {
            setPlanDetails(planId)
            setEditButton(planDetails.edit_button)
            setIsVisible(planDetails.is_visible)
            setIsEnabled(planDetails.is_enabled)
            const defaultTextNoteScreenType = noteScreenType.find((item) => item.value === planDetails.edit_button_action) || initialValueLabel
            setSelectedOptionOfNoteType(defaultTextNoteScreenType)
            // setSelectedOptionOfNoteType({  })
        } else {
            resetPlanDetails()
            planDetails.plan_description = []
        }
    }, [planDetails.edit_button, planDetails.is_enabled, planDetails.is_visible, planDetails.edit_button_action])

    const handleClick = () => {
        resetPlanDetails()
        history.push('/plans/')
    }

    const openCloseDescriptionInfoModal = () => {
        setShowDescriptionInfoModel(!showDescriptionInfoModel)
    }

    const onActionClicked = (action: string, data: any) => {
        if (action === 'addDescriptionInfo') {
            setDescriptionInfo(new DescriptionInfoStore(initialDescriptionInfo))
            setModalTitle('Add Description Information')
            openCloseDescriptionInfoModal()
        }
        if (action === 'editDescriptionInfo') {
            setDescriptionInfo(data)
            setModalTitle('Edit Description Information')
            openCloseDescriptionInfoModal()
        }
        if (action === 'deleteDescriptionInfo') {
            planStore.deleteDescriptionInfo(data)
        }
    }

    const onSubmit = async (data: Dictionary<string>) => {
        const priceForSingle = {
            price: data.price_for_single,
            level_1_price: data.price_for_single_level_1,
            level_2_price: data.price_for_single_level_2,
            level_3_price: data.price_for_single_level_3,
            restatement_price: data.restatement_price_for_single,
            restatement_level_1_price: data.restatement_price_for_single_level_1,
            restatement_level_2_price: data.restatement_price_for_single_level_2,
            restatement_level_3_price: data.restatement_price_for_single_level_3
        }

        const priceForMarried = {
            price: data.price_for_married,
            level_1_price: data.price_for_married_level_1,
            level_2_price: data.price_for_married_level_2,
            level_3_price: data.price_for_married_level_3,
            restatement_price: data.restatement_price_for_married,
            restatement_level_1_price: data.restatement_price_for_married_level_1,
            restatement_level_2_price: data.restatement_price_for_married_level_2,
            restatement_level_3_price: data.restatement_price_for_married_level_3
        }

        let noteType = selectedOptionOfNoteType.value
        if(!editButton) {
            noteType = ''
        }

        const requestData = {
            _id: planId,
            name: data.name,
            display_order: data.display_order,
            plan_description: planDetails.plan_description,
            price_for_single: priceForSingle,
            price_for_married: priceForMarried,
            edit_button: editButton,
            edit_button_action: noteType,
            is_visible: isVisible,
            is_enabled: isEnabled
        }

        const response = await addUpdateSinglePlan(requestData)
        if (response === 'true') {
            history.push('/plans/')
        }
    }

    const handleIsVisible = (e: any) => {
        const value = e.target.checked
        setIsVisible(value)
    }

    const handleIsEnabled = (e: any) => {
        const value = e.target.checked
        setIsEnabled(value)
    }

    const handleEditButton = (e: any) => {
        const value = e.target.checked
        setEditButton(value)
    }

    const handleOnNoteTypeChange = (data: any) => {
        if (data !== null) {
            setSelectedOptionOfNoteType(data)
        }
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Plans', to: '/plans/' },
            { name: (pageName === 'Update Plan') ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(planId === planDetails._id)
                            ? <>
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <fieldset>

                                        <InputField
                                            label='Name *'
                                            fieldName='name'
                                            register={register}
                                            errors={errors.name}
                                            placeholder='Enter Name'
                                            defaultValue={planDetails.name}
                                            required={true}
                                        />

                                        <div className="form-group">
                                            <label>Display Order *</label>
                                            <input
                                                ref={register({
                                                    required: 'This field is required'
                                                })}
                                                name='display_order'
                                                type='number'
                                                min={1}
                                                className={classNames('form-control', { 'is-invalid': errors.display_order })}
                                                defaultValue={planDetails.display_order}
                                            />
                                            {errors.display_order && (<div className='invalid-feedback'>{errors.display_order.message}</div>)}
                                        </div>
                                        <div className="custom-control form-group mt-1 custom-switch">
                                            <input type="checkbox" defaultChecked={planDetails.is_visible}
                                                ref={register}
                                                className="custom-control-input" name='is_visible' id="isVisible" 
                                                onClick={handleIsVisible} 
                                            />
                                            <label className="custom-control-label" htmlFor='isVisible'>Is Visible</label>
                                            {errors.is_visible ? (<div className='invalid-feedback'>{errors.is_visible.message}</div>) : <small className='form-text text-muted switch-help-text'>This flag controls the visibility of the plan in the plan screen. You can always able to select it from the agreement screen.</small>}
                                        </div>
                                        <div className="custom-control form-group mt-1 custom-switch">
                                            <input type="checkbox" defaultChecked={planDetails.is_enabled}
                                                ref={register}
                                                className="custom-control-input" name='is_enabled' id="isEnabled" 
                                                onClick={handleIsEnabled} 
                                            />
                                            <label className="custom-control-label" htmlFor='isEnabled'>Is Enabled</label>
                                            {errors.is_enabled ? (<div className='invalid-feedback'>{errors.is_enabled.message}</div>) : <small className='form-text text-muted switch-help-text'>This flag controls the availability of the plan from every places.</small>}
                                        </div>
                                        <div className='form-group'>
                                            <div>
                                            Description
                                                <div className='card mt-2'>
                                                    <div className='card-body'>
                                                        <div className='float-right mb-4'>
                                                            <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                                onActionClicked('addDescriptionInfo', descriptionInfo)
                                                            }}> + Add</button>
                                                        </div>
                                                        <div className='table-responsive'>
                                                            <Table
                                                                unique_key='label'
                                                                columns={[
                                                                    { name: 'type', title: 'Description Type', component: TableCellDescriptionType },
                                                                    { name: 'text', title: 'Text of Description', class: 'pre-wrap' },
                                                                    { name: 'image', title: 'Image name of Description', component: TableCellImageType },
                                                                    { name: '', title: 'Action', component: EditDescriptionInfo },
                                                                    { name: '', title: '', component: DeleteDescriptionInfo }
                                                                ]}
                                                                onTableAction={onActionClicked}
                                                                data={planDetails.plan_description}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label>Price List for Single *</label>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className='form-row'>
                                                        <div className='col-md-2'>
                                                            <label>Restatement</label><br/>
                                                            <label>No</label>
                                                        </div>
                                                        <InputFieldForVisionPlan
                                                            label='Initial'
                                                            fieldName='price_for_single'
                                                            register={register}
                                                            errors={errors.price_for_single}
                                                            placeholder='Enter Initial Price'
                                                            defaultValue={planDetails.price_for_single.price}
                                                            required={true}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-1'
                                                            fieldName='price_for_single_level_1'
                                                            register={register}
                                                            errors={errors.price_for_single_level_1}
                                                            placeholder='Enter Level-1 Price'
                                                            defaultValue={planDetails.price_for_single.level_1_price}
                                                            required={true}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-2'
                                                            fieldName='price_for_single_level_2'
                                                            register={register}
                                                            errors={errors.price_for_single_level_2}
                                                            placeholder='Enter Level-2 Price'
                                                            defaultValue={planDetails.price_for_single.level_2_price}
                                                            required={true}
                                                        />
                                                        <InputFieldForVisionPlan
                                                            label='Level-3'
                                                            fieldName='price_for_single_level_3'
                                                            register={register}
                                                            errors={errors.price_for_single_level_3}
                                                            placeholder='Enter Level-3 Price'
                                                            defaultValue={planDetails.price_for_single.level_3_price}
                                                            required={true}
                                                        />

                                                        <div className='w-100'></div>
                                                        <div className='col-md-2'>
                                                            <label>Yes</label>
                                                        </div>
                                                        <InputFieldForVisionPlan
                                                            label='Initial'
                                                            fieldName='restatement_price_for_single'
                                                            register={register}
                                                            errors={errors.restatement_price_for_single}
                                                            placeholder='Enter Restatement Initial'
                                                            defaultValue={planDetails.price_for_single.restatement_price}
                                                            required={true}
                                                            needLabel={false}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-1'
                                                            fieldName='restatement_price_for_single_level_1'
                                                            register={register}
                                                            errors={errors.restatement_price_for_single_level_1}
                                                            placeholder='Enter Restatement Level-1'
                                                            defaultValue={planDetails.price_for_single.restatement_level_1_price}
                                                            required={true}
                                                            needLabel={false}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-2'
                                                            fieldName='restatement_price_for_single_level_2'
                                                            register={register}
                                                            errors={errors.restatement_price_for_single_level_2}
                                                            placeholder='Enter Restatement Level-2'
                                                            defaultValue={planDetails.price_for_single.restatement_level_2_price}
                                                            required={true}
                                                            needLabel={false}
                                                        />
                                                        <InputFieldForVisionPlan
                                                            label='Level-3'
                                                            fieldName='restatement_price_for_single_level_3'
                                                            register={register}
                                                            errors={errors.restatement_price_for_single_level_3}
                                                            placeholder='Enter Restatement Level-3'
                                                            defaultValue={planDetails.price_for_single.restatement_level_3_price}
                                                            required={true}
                                                            needLabel={false}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label>Price List for Married *</label>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className='form-row'>
                                                        <div className='col-md-2'>
                                                            <label>Restatement</label><br/>
                                                            <label>No</label>
                                                        </div>
                                                        <InputFieldForVisionPlan
                                                            label='Initial'
                                                            fieldName='price_for_married'
                                                            register={register}
                                                            errors={errors.price_for_married}
                                                            placeholder='Enter Initial Price'
                                                            defaultValue={planDetails.price_for_married.price}
                                                            required={true}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-1'
                                                            fieldName='price_for_married_level_1'
                                                            register={register}
                                                            errors={errors.price_for_married_level_1}
                                                            placeholder='Enter Level-1 Price'
                                                            defaultValue={planDetails.price_for_married.level_1_price}
                                                            required={true}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-2'
                                                            fieldName='price_for_married_level_2'
                                                            register={register}
                                                            errors={errors.price_for_married_level_2}
                                                            placeholder='Enter Level-2 Price'
                                                            defaultValue={planDetails.price_for_married.level_2_price}
                                                            required={true}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-3'
                                                            fieldName='price_for_married_level_3'
                                                            register={register}
                                                            errors={errors.price_for_married_level_3}
                                                            placeholder='Enter Level-3 Price'
                                                            defaultValue={planDetails.price_for_married.level_3_price}
                                                            required={true}
                                                        />

                                                        <div className='w-100'></div>
                                                        <div className='col-md-2'>
                                                            <label>Yes</label>
                                                        </div>
                                                        <InputFieldForVisionPlan
                                                            label='Initial'
                                                            fieldName='restatement_price_for_married'
                                                            register={register}
                                                            errors={errors.restatement_price_for_married}
                                                            placeholder='Enter Restatement Initial'
                                                            defaultValue={planDetails.price_for_married.restatement_price}
                                                            required={true}
                                                            needLabel={false}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-1'
                                                            fieldName='restatement_price_for_married_level_1'
                                                            register={register}
                                                            errors={errors.restatement_price_for_married_level_1}
                                                            placeholder='Enter Restatement Level-1'
                                                            defaultValue={planDetails.price_for_married.restatement_level_1_price}
                                                            required={true}
                                                            needLabel={false}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-2'
                                                            fieldName='restatement_price_for_married_level_2'
                                                            register={register}
                                                            errors={errors.restatement_price_for_married_level_2}
                                                            placeholder='Enter Restatement Level-2'
                                                            defaultValue={planDetails.price_for_married.restatement_level_2_price}
                                                            required={true}
                                                            needLabel={false}
                                                        />

                                                        <InputFieldForVisionPlan
                                                            label='Level-3'
                                                            fieldName='restatement_price_for_married_level_3'
                                                            register={register}
                                                            errors={errors.restatement_price_for_married_level_3}
                                                            placeholder='Enter Restatement Level-3'
                                                            defaultValue={planDetails.price_for_married.restatement_level_3_price}
                                                            required={true}
                                                            needLabel={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-control form-group mt-1 custom-switch">
                                            <input type="checkbox" defaultChecked={planDetails.edit_button}
                                                ref={register}
                                                className="custom-control-input" name='edit_button' id="editButton" 
                                                onClick={handleEditButton} 
                                            />
                                            <label className="custom-control-label" htmlFor='editButton'>Need Edit Button</label>
                                            {errors.edit_button ? (<div className='invalid-feedback'>{errors.edit_button.message}</div>) : <small className='form-text text-muted switch-help-text'></small>}
                                        </div>

                                        <div className={classNames({ 'd-none': (!editButton) })}>
                                            <div className='form-group'>
                                                <label>Edit Button Action *</label>
                                                <ReactSelectComponent
                                                    options={noteScreenType}
                                                    value={selectedOptionOfNoteType}
                                                    handleOnChangeReact={handleOnNoteTypeChange}
                                                    helpText={<><span>Graph Note - To Show Edit Note Screen with Income Graph.</span><br></br><span>
                                                    Note - To Show a whole Blank screen to use as a simple note screen.</span></>}
                                                />
                                            </div>
                                        </div>

                                        <div className='form-group'>
                                            <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                                                {isLoading ? 'Loading...' : planId === '' ? 'Submit': 'Update'}
                                            </button>&nbsp;&nbsp;
                                            <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                        </div>
                                    </fieldset>
                                </form>
                                {showDescriptionInfoModel ? <DescriptionInfoModel options={optionForDescriptionInfoType} imageTypeOption={imageTypeText} modalTitle={modalTitle} showModel={showDescriptionInfoModel} targetData={descriptionInfo} planStore={planStore} openCloseForm={openCloseDescriptionInfoModal} /> : null}
                            </>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdatePlanPage)