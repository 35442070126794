import React, { useEffect } from 'react'
import RootStore from '../../../store/Root'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Article } from '../../commonComponent/DotLoader'
import { FormateDate, ShowVisionDetails, dateFormat } from '../../commonComponent/commonFuction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb } from '../../commonComponent/BreadcrumbComponent'
import paymentTypePlan from './TypeList'
import Table from '../../table/Table'
import uuid from 'react-uuid'
import { AudioPlayer } from '../audio/AudioPage'

interface Props {
  rootStore: RootStore,
}

const ConvertBooleanToString = (value: boolean) => {

    return (value === true ? 'Yes' : 'No' )
}

const getPaymentPlan = (value: string) => {
    const plan = paymentTypePlan.filter(paymentPlan => { return paymentPlan.value === value } )
    if (plan.length != 0) {
        return plan[0].label
    }
    return ''
}

const VisionDetailsPage: React.FC<Props> = ({ rootStore }) => {
    const { visionStore, authStore } = rootStore
    const { isAdmin } = authStore
    const { isApiError, isLoading, apiErrorMessage, singleVisionDetails,  setVisionDetails } = visionStore

    const history = useHistory()
    const visionArrayOfUrl = history.location.pathname.split('/')
    const visionId = visionArrayOfUrl[3] 

    useEffect(() => {
        setVisionDetails(visionId)

    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Visions', to: '/visions/' },
            { name: 'Details', isActive: true }
        ]} />
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                        Vision Details
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}
                            
                        {isLoading? <Article /> : <div>
                            <h5><b>Vision Summary:</b></h5>
                            <div className="card" >
                                <div className="card-body">
                                    <ShowVisionDetails title='Client Name' value={singleVisionDetails.client_name} />
                                    <ShowVisionDetails title='Modifier Name' value={singleVisionDetails.modified_by} />
                                    <ShowVisionDetails title='Added by' value={singleVisionDetails.added_by} />
                                    <ShowVisionDetails title='Clients are married and planning together' value={ ConvertBooleanToString(singleVisionDetails.is_married)} />
                                    <ShowVisionDetails title='Clients has a trust and needs a restatement' value={ ConvertBooleanToString(singleVisionDetails.is_client_has_trust)} />
                                    <ShowVisionDetails title='Total investable assets' value={(singleVisionDetails.assets ? '$' + singleVisionDetails.assets : '')} />
                                    <ShowVisionDetails title='Plan Name' value={singleVisionDetails.agreement.plan} />
                                    <ShowVisionDetails title='Payment Plan' value={getPaymentPlan(singleVisionDetails.agreement.payment_plan)} />
                                    <br/>
                                    <div>{
                                        (singleVisionDetails.agreement.payment_plan !== '' && singleVisionDetails.agreement.payment_plan === 'one_time_in_full') ?
                                            <span>The attorney&apos;s fees for these services after discount are <strong>$ {singleVisionDetails.agreement.attorney_fee_first_price}</strong>.</span>
                                            : (singleVisionDetails.agreement.payment_plan !== '' && (singleVisionDetails.agreement.payment_plan === 'six_month_payment' || singleVisionDetails.agreement.payment_plan === 'twelve_month_payment' || singleVisionDetails.agreement.payment_plan === 'twenty_four_month_payment')) ? 
                                                <span>The attorney&apos;s fees for these services after discount are <strong>$ {singleVisionDetails.agreement.attorney_fee_first_price}</strong>, or <strong>$ {singleVisionDetails.agreement.attorney_fee_second_price}</strong> per month for <strong>{singleVisionDetails.agreement.number_of_month_for_attorney_fee}</strong> months.</span>
                                                : (singleVisionDetails.agreement.payment_plan !== '' && singleVisionDetails.agreement.payment_plan === 'half_in_payment') ? <span><strong>$ {singleVisionDetails.agreement.attorney_fee_first_price}</strong> today and <strong>$ {singleVisionDetails.agreement.attorney_fee_second_price}</strong> at signing.</span>: ''
                                    }
                                    </div>
                                </div>
                            </div>
                            
                            <hr />
                            <h5><b>Basic Vision Details:</b></h5>
                            <div className="card" >
                                <div className="card-body">
                                    <ShowVisionDetails title='Client Name' value={singleVisionDetails.client_name} />
                                    <ShowVisionDetails title='Modifier Name' value={singleVisionDetails.modified_by} />
                                    <ShowVisionDetails title='Is EP+ Qualified?' value={singleVisionDetails.lawmatics_ep_qualified} />
                                    {singleVisionDetails.ep_qualified_at ? <ShowVisionDetails title='EP+ Qualified At' value={dateFormat(singleVisionDetails.ep_qualified_at)} /> : '' }
                                    <ShowVisionDetails title='Created At' value={singleVisionDetails.createdAt} />
                                    <ShowVisionDetails title='Updated At' value={singleVisionDetails.updatedAt} />
                                </div>
                            </div>
                            
                            <hr />
                            <h5><b>Notes:</b></h5>
                            { singleVisionDetails.notes.length !== 0 ? singleVisionDetails.notes.map((item, i) => 
                            { return <div className="card" key={item._id}>
                                <div id={item._id} >
                                    <div className="card-body">
                                        {item.note}
                                    </div>
                                </div>
                            </div>
                            }) : <div className="card">
                                <div className="card-header " key='name'>
                                        Not any Note
                                </div>
                            </div> }
                            <hr/>
                            <h5><b>Estate Plan Problem Reviews:</b> </h5>
                            { singleVisionDetails.estate_plan_problem_reviews.length !== 0 ? singleVisionDetails.estate_plan_problem_reviews.map((item, i) => 
                            { return <div className="card" key={item._id}>
                                <div id={item._id}>
                                    <div className="card-body">
                                        <strong>{item.title}</strong>
                                        {item.options.map((i, index) => {
                                            return <div key={i._id}> {index +1}.&nbsp;{i.type === 'CHECKBOX' ? i.label : (i.label ? (i.label + ': ' + 
                                                (i.type === 'AMOUNT' && i.text_answer ? ('$' + i.text_answer) : i.text_answer )) : i.text_answer)}
                                            </div>
                                        }) }
                                    </div>
                                </div>
                            </div>
                            }) : <div className="card">
                                <div className="card-header " key='name'>
                                    Not any selected Questions
                                </div>
                            </div>  }
                            <hr/>  
                            <h5><b>Engagement Details:</b> </h5>
                            <div className="card">
                                <div className="card-body">
                                    <ShowVisionDetails title='Plan Name' value={singleVisionDetails.agreement.plan} />
                                    <ShowVisionDetails title='First Signature Date' value={singleVisionDetails.agreement.signature_1_date} />
                                    { singleVisionDetails.agreement.is_second_signature_show === true ? <>
                                        <ShowVisionDetails title='Second Signature Date' value={singleVisionDetails.agreement.signature_2_date} />
                                        { singleVisionDetails.agreement.is_third_signature_show === true ? <>
                                            <ShowVisionDetails title='Third Signature Date' value={singleVisionDetails.agreement.signature_3_date} />
                                        </> : null} 
                                    </> : null}         
                             
                                    {/* <ShowVisionDetails title='Acknowledgment Phone Number' value={singleVisionDetails.agreement.acknowledgment_phone_number} /> */}
                                    <ShowVisionDetails title='Attorney Fee First Price' value={singleVisionDetails.agreement.attorney_fee_first_price ? ('$' + singleVisionDetails.agreement.attorney_fee_first_price) : singleVisionDetails.agreement.attorney_fee_first_price} />
                                    <ShowVisionDetails title='Attorney Fee Second Price' value={singleVisionDetails.agreement.attorney_fee_second_price ? ('$' + singleVisionDetails.agreement.attorney_fee_second_price) : ''} />
                                    <ShowVisionDetails title='Number of Month for Attorney Fee' value={singleVisionDetails.agreement.number_of_month_for_attorney_fee} />
                                    <ShowVisionDetails title='Is Agree and Signing' value={ConvertBooleanToString(singleVisionDetails.agreement.is_agree_and_signing)} />
                                    <ShowVisionDetails title='Acknowledgment Email Address' value={singleVisionDetails.agreement.acknowledgment_email_address} />
                                    
                                    <ShowVisionDetails title='Is Acknowledgment Send' value={ConvertBooleanToString(singleVisionDetails.agreement.is_acknowledgment_send)} />
                                    <ShowVisionDetails title='Is Mail sent' value={ConvertBooleanToString(singleVisionDetails.agreement.is_mail_sent)} />
                                    {singleVisionDetails.agreement.acknowledgments !== undefined && singleVisionDetails.agreement.acknowledgments.length !== 0 && <div>
                                        <hr/>  
                                        <h6><b>Additional Email Details:</b></h6>
                                        <div className="card">
                                            <div className="card-body">
                                                {singleVisionDetails.agreement.acknowledgments.map((additionEmail) => {
                                                    return <>
                                                        <ShowVisionDetails title='Acknowledgment Email Address' value={additionEmail.acknowledgment_email_address} />
                                                        <ShowVisionDetails title='Is Acknowledgment Send' value={ConvertBooleanToString(additionEmail.is_acknowledgment_send)} />
                                                        <ShowVisionDetails title='Is Mail sent' value={ConvertBooleanToString(additionEmail.is_mail_sent)} />
                                                    </>
                                                })}
                                            </div>
                                        </div>
                                    </div>}
                                    {isAdmin ?<><ShowVisionDetails title='Client created' value={ConvertBooleanToString(singleVisionDetails.agreement.is_client_created)} />
                                        {singleVisionDetails.agreement.is_client_created ?<ShowVisionDetails title='Client created At' value={dateFormat(singleVisionDetails.agreement.client_created_at)} /> : '' }</> : '' }
                                    <ShowVisionDetails title='File Uploaded to Lawmatics' value={ConvertBooleanToString(singleVisionDetails.agreement.is_file_uploaded_to_lm)} />
                                    {singleVisionDetails.agreement.is_file_uploaded_to_lm ? <ShowVisionDetails title='File Uploaded At' value={dateFormat(singleVisionDetails.agreement.file_uploaded_at)} /> : '' }
                                </div>
                            </div>
                            <hr/>
                            
                            <h5><b>Estate Protect Plan:</b> </h5>
                            { singleVisionDetails.estate_protect_plan.length !== 0 && singleVisionDetails.estate_protect_plan[0].estate_protect_plan_id !== undefined ? singleVisionDetails.estate_protect_plan.map((item, i) => 
                            { return <>
                                <div className="card">
                                    <div className="card-header " key='name'>
                                        <div className='row'>
                                            <div className='col col-lg-2'>
                                                <span className='mr-2'>{item.estate_protect_plan_name}</span>
                                            </div>
                                            <div className='col'>
                                                <span className='mr-2'>Percentage: {item.percentage === '' ? 0 : item.percentage}</span> {item.is_selected ? <FontAwesomeIcon data-toggle="tooltip" title='Selected Plan' className='text-success'  icon={faCheckCircle} /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }) : <div className="card">
                                <div className="card-header" key='name'>
                                        There is not any plan
                                </div>
                            </div> }   
                            <hr/>  
                            <h5><b>Client Rights Disclosure Details:</b> </h5>
                            <div className="card">
                                <div className="card-body">
                                    
                                   
                                    <ShowVisionDetails title='Date of Disclosure' value={singleVisionDetails.client_rights_disclosure.date_of_disclosure} />
                                    
                                    <ShowVisionDetails title='Is Agree and Signing' value={ConvertBooleanToString(singleVisionDetails.client_rights_disclosure.is_agree_and_signing)} />
                                    <ShowVisionDetails title='Acknowledgment Email Address' value={singleVisionDetails.client_rights_disclosure.acknowledgment_email_address} />                         
                                    <ShowVisionDetails title='Is Acknowledgment Send' value={ConvertBooleanToString(singleVisionDetails.client_rights_disclosure.is_acknowledgment_send)} />
                                    <ShowVisionDetails title='Is Mail sent' value={ConvertBooleanToString(singleVisionDetails.client_rights_disclosure.is_mail_sent)} />
                                </div>
                            </div>
                            <hr/>
                            <h5><b>Wealth Advisory Agreement Details:</b> </h5>
                            <div className="card">
                                <div className="card-body">
                                    <ShowVisionDetails title='Client Name' value={singleVisionDetails.wealth_advisory_agreement.client_name} />
                                    <ShowVisionDetails title='Advisor Name' value='Rod Yancy' />
                                    {/* <ShowVisionDetails title='Advisor Signature' value='https://oathzephyr-dev.s3.amazonaws.com/rod_sign/rodsignature.png' type='Image'/> */}
                                    {/* <ShowVisionDetails title='Advisor Signature' value='https://oathzephyr.s3.amazonaws.com/static_signature/rodsignature.png' type='Image'/> */}
                                    <ShowVisionDetails title='First Client Name' value={singleVisionDetails.wealth_advisory_agreement.first_client_name} />
                                    { singleVisionDetails.wealth_advisory_agreement.is_second_signature_show === true ? <>
                                        <ShowVisionDetails title='Second Client Name' value={singleVisionDetails.wealth_advisory_agreement.second_client_name} />
                                    </> : null}
                                    
                                    <ShowVisionDetails title='Date of Contract Signing' value={singleVisionDetails.wealth_advisory_agreement.date_of_contract_signing} />
                                    
                                    <ShowVisionDetails title='Is Agree and Signing' value={ConvertBooleanToString(singleVisionDetails.wealth_advisory_agreement.is_agree_and_signing)} />
                                    <ShowVisionDetails title='Acknowledgement Email Address' value={singleVisionDetails.wealth_advisory_agreement.acknowledgment_email_address} />
                                    
                                    <ShowVisionDetails title='Is Acknowledgement Sent' value={ConvertBooleanToString(singleVisionDetails.wealth_advisory_agreement.is_acknowledgment_send)} />
                                    <ShowVisionDetails title='Is Mail Sent' value={ConvertBooleanToString(singleVisionDetails.wealth_advisory_agreement.is_mail_sent)} />
                                </div>
                            </div>
                            {isAdmin ?
                                <>
                                    <hr/>  
                                    <h5><b>Signatures:</b></h5>
                                    <div className="card">
                                        <div className="card-body">
                                            <ShowVisionDetails title='Engagement First Signature' value={ singleVisionDetails.agreement_first_signature === undefined || singleVisionDetails.agreement_first_signature === '' ? '/placeholder-image.png' : singleVisionDetails.agreement_first_signature } type='Image'/>
                                            { singleVisionDetails.agreement.is_second_signature_show === true ? <>
                                                <ShowVisionDetails title='Engagement Second Signature' value={ singleVisionDetails.agreement_second_signature === undefined || singleVisionDetails.agreement_second_signature === '' ? '/placeholder-image.png' : singleVisionDetails.agreement_second_signature } type='Image'/>
                                                { singleVisionDetails.agreement.is_third_signature_show === true ? <>
                                                    <ShowVisionDetails title='Engagement Third Signature' value={ singleVisionDetails.agreement_third_signature === undefined || singleVisionDetails.agreement_third_signature === '' ? '/placeholder-image.png' : singleVisionDetails.agreement_third_signature } type='Image'/>
                                                </> : null }
                                            </> : null}

                                            <ShowVisionDetails title='CRD First Signature' value={ singleVisionDetails.crd_first_signature === undefined || singleVisionDetails.crd_first_signature === '' ? '/placeholder-image.png' : singleVisionDetails.crd_first_signature} type='Image'/>

                                            { singleVisionDetails.client_rights_disclosure.is_second_signature_show === true ? <>
                                                <ShowVisionDetails title='CRD Second Signature' value={singleVisionDetails.crd_second_signature === undefined || singleVisionDetails.crd_second_signature === ''  ? '/placeholder-image.png' : singleVisionDetails.crd_second_signature} type='Image'/>
                                            </> : null}  

                                            <ShowVisionDetails title='WAA First Client Signature' value={singleVisionDetails.contract_first_signature === undefined || singleVisionDetails.contract_first_signature === '' ? '/placeholder-image.png' : singleVisionDetails.contract_first_signature} type='Image'/>  

                                            { singleVisionDetails.wealth_advisory_agreement.is_second_signature_show === true ? <>
                                                <ShowVisionDetails title='WAA Second Client Signature' value={singleVisionDetails.contract_second_signature === undefined || singleVisionDetails.contract_second_signature === '' ? '/placeholder-image.png' : singleVisionDetails.contract_second_signature} type='Image'/>
                                            </> : null}    
                                        </div>
                                    </div>

                                    <hr/>  
                                    <h5><b>Lawmatics Details:</b></h5> 
                                    <div className="card">
                                        <div className="card-body">
                                            <ShowVisionDetails title='Matter Id' value={singleVisionDetails.lawmatics_matter_id} />
                                            <ShowVisionDetails title='Contact Id' value={singleVisionDetails.lawmatics_contact_id} />
                                            <ShowVisionDetails title='Probable Id' value={singleVisionDetails.zephyr_probable_uid} />
                                        </div>
                                    </div>

                                    <hr/> 
                                    <h5><b>Recordings:</b> </h5>

                                    <div className='table-responsive mt-3'>
                                        <Table
                                            isLoading={isLoading}
                                            unique_key={uuid()}
                                            columns={[
                                                { name: 'audio', title: 'Recording', component: AudioPlayer },
                                                { name: 'audio_length', title: 'Length (in sec)' },
                                                { name: 'createdAt', title: 'Created At', component: FormateDate },
                                            ]}
                                            data={singleVisionDetails.recordings}
                                        ></Table>
                                    </div>
                                </> : null 
                            }
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(VisionDetailsPage)