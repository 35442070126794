import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../../layout/Container'
import { useHistory } from 'react-router-dom'
import InputField from '../../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../../store/types.d'
import classNames from 'classnames'
import Table from '../../table/Table'
import { questionOptionType } from '../../field/TypeList'
import OptionInfoModel from './OptionInfoModel'
import { Cell } from '../../table/TableCellText.d'
import _ from 'lodash'
import { initialOptionInfo } from '../../../store/InitialValues'
import OptionInfoStore from '../../../store/vision/OptionInfo'
import { DeleteOptionInfo, EditOptionInfo } from '../../commonComponent/EditDeleteLabelDecorationCellComponent'

const optionType = questionOptionType.map((list: any) => {
    const data = {
        value: list.value || '',
        label: list.label || ''
    }
    return data
})

const TableCellOptionType: React.FC<Cell> = (props) => {
    let value = props.value || ''
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    const type = _.find(questionOptionType, { value: value })
    return (
        <>
            {type?.label}
        </>
    )
}


const AddUpdatePlanPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { problemQuestionStore, planStore } = rootStore
    const { isApiError, isLoading, apiErrorMessage, problemQuestionDetails, addUpdateSingleProblemQuestion, setProblemQuestionDetails, resetProblemQuestionDetails } = problemQuestionStore
    const { planNameId } = planStore

    const [showOptionInfoModel, setShowOptionInfoModel] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [optionInfo, setOptionInfo] = useState<OptionInfoStore>()

    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add Problem Question' : 'Update Problem Question'
    const pathArrayWithProblemQuestionId = history.location.pathname.split('/')

    const problemQuestionId = (pageName === 'Update Problem Question') ? pathArrayWithProblemQuestionId[3] : ''

    useEffect(() => {
        if (pageName === 'Update Problem Question') {
            setProblemQuestionDetails(problemQuestionId)
        } else {
            resetProblemQuestionDetails()
            problemQuestionDetails.options = []
        }
    }, [planNameId])

    const handleClick = () => {
        resetProblemQuestionDetails()
        history.push('/problem-questions/')
    }

    const openCloseOptionInfoModal = () => {
        setShowOptionInfoModel(!showOptionInfoModel)
    }

    const onActionClicked = (action: string, data: any) => {
        if (action === 'addOptionInfo') {
            setOptionInfo(new OptionInfoStore(initialOptionInfo))
            setModalTitle('Add Option Information')
            openCloseOptionInfoModal()
        }
        if (action === 'editOptionInfo') {
            setOptionInfo(data)
            setModalTitle('Edit Option Information')
            openCloseOptionInfoModal()
        }
        if (action === 'deleteOptionInfo') {
            problemQuestionStore.deleteOptionInfo(data)
        }
    }

    const onSubmit = async (data: Dictionary<string>) => {
        const requestData = {
            _id: problemQuestionId,
            title: data.title,
            sub_title: data.sub_title,
            display_order: data.display_order,
            options: problemQuestionDetails.options
        }

        const response = await addUpdateSingleProblemQuestion(requestData)
        if (response === 'true') {
            history.push('/problem-questions/')
        }
    }
    
    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Problem Questions', to: '/problem-questions/' },
            { name: (pageName === 'Update Problem Question') ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(problemQuestionId === problemQuestionDetails._id)
                            ? <>
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <fieldset>

                                        <InputField
                                            label='Title'
                                            fieldName='title'
                                            register={register}
                                            errors={errors.title}
                                            placeholder='Enter Title'
                                            defaultValue={problemQuestionDetails.title}
                                            required={true}
                                        />

                                        <InputField
                                            label='Sub Title'
                                            fieldName='sub_title'
                                            register={register}
                                            errors={errors.sub_title}
                                            placeholder='Enter Sub Title'
                                            defaultValue={problemQuestionDetails.sub_title}
                                        />

                                        <div className="form-group">
                                            <label>Display Order</label>
                                            <input
                                                ref={register({
                                                    required: 'This field is required'
                                                })}
                                                name='display_order'
                                                type='number'
                                                min={1}
                                                className={classNames('form-control', { 'is-invalid': errors.display_order })}
                                                defaultValue={problemQuestionDetails.display_order}
                                            />
                                            {errors.display_order && (<div className='invalid-feedback'>{errors.display_order.message}</div>)}
                                        </div>

                                        <div className='form-group'>
                                            <div>
                                            Options
                                                <div className='card mt-2'>
                                                    <div className='card-body'>
                                                        <div className='float-right mb-4'>
                                                            <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                                onActionClicked('addOptionInfo', optionInfo)
                                                            }}> + Add</button>
                                                        </div>
                                                        <div className='table-responsive'>
                                                            <Table
                                                                unique_key='label'
                                                                columns={[
                                                                    { name: 'label', title: 'Label' },
                                                                    { name: 'placeholder', title: 'Placeholder' },
                                                                    { name: 'type', title: 'Option Type', component: TableCellOptionType },
                                                                    { name: 'display_order', title: 'Display Order' },
                                                                    { name: '', title: 'Action',  component: EditOptionInfo },
                                                                    { name: '', title: '', component: DeleteOptionInfo }
                                                                ]}
                                                                onTableAction={onActionClicked}
                                                                data={problemQuestionDetails.options}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='form-group'>
                                            <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                                                {isLoading ? 'Loading...' : problemQuestionId === '' ? 'Submit': 'Update'}
                                            </button>&nbsp;&nbsp;
                                            <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                        </div>
                                    </fieldset>
                                </form>
                                {showOptionInfoModel ? <OptionInfoModel modalTitle={modalTitle} showModel={showOptionInfoModel} targetData={optionInfo} problemQuestionStore={problemQuestionStore} openCloseForm={openCloseOptionInfoModal} typeOptions={optionType} planOptions={planNameId}/> : null}
                            </>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdatePlanPage)