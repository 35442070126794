import React, { useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { Cell } from '../table/TableCellText.d'
import FormModal from '../commonComponent/FormModal'
import { Dictionary } from '../../store/types.d'
import { updateTemplate, addUpdateFooterTextInTemplate } from '../../api/TemplateActions'
import { JointIndividualTypesCell } from '../commonComponent/JoiniIndividualTypesCellComponent'

interface Props {
  rootStore: RootStore,
}

const TemplatePreviewButton: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data || {}

    const [selectedRow, setSelectedRow] = useState({ _id: '', action: '' })
    const [showUpdateOrderModal, setShowUpdateOrderModal] = useState(false)
    const [footerText, setFooter] = useState(data.footer_text)

    const openCloseModal = () => {
        setShowUpdateOrderModal(!showUpdateOrderModal)
    }

    const onTableAction = (action: string, value: any, data: any) => {
        setSelectedRow({ _id: data._id, action: action })
        openCloseModal()
    }

    const isValueEquivalent = (a: any, b: any) => {
        if (!_.isEqual(a, b)) {
            return false
        }
        return true
    }

    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    const onUpdateOrder = async (updateData: Dictionary<string>) => {
        await updateTemplate({ _id: data._id, name: data.name, display_order: updateData.display_order })
        data.fetchTemplate(data.page, data.searchItem)
        openCloseModal()
    }

    const addUpdateFooter = async () => {
        await addUpdateFooterTextInTemplate({ _id: data._id, footer_text: footerText })
        await data.fetchTemplate(data.page, data.searchItem)
        openCloseModal()
    }

    const onchangeFooter = (e:any) => {
        setFooter(e.target.value)
    }

    const { register, handleSubmit, errors } = useForm()

    return (
        <>
            <Link to={`/templates/update/preview/${value}`} className='mr-2'>Update</Link>
            <Link to={`/templates/manage/fields/${value}`} className='mr-2'>Manage Fields</Link>
            <Link className='mr-2' to='#' onClick={() => {
                if (onTableAction != null) {
                    onTableAction('updateOrder', value, data)
                }
            }} >Set Display Order</Link>
            <Link to='#' onClick={() => {
                if (onTableAction != null) {
                    onTableAction('addUpdateFooter', value, data)
                }
            }} >Set Footer Text</Link>

            {showUpdateOrderModal && isValueEquivalent({ _id: data._id, action: 'updateOrder' }, selectedRow) && (
                <FormModal show={showUpdateOrderModal}
                    modalTitle={'Update ' + data.name + '\'s Display Order'}
                    openCloseModal={openCloseModal}
                >
                    <form onSubmit={handleSubmit(onUpdateOrder)} className='modal-body'>
                        <fieldset>
                            <div className='form-group'>
                                <label>Display Order</label>
                                <input type='number'
                                    name='display_order'
                                    min={1}
                                    className={classNames('form-control', { 'is-invalid': errors.display_order })}
                                    defaultValue={data.display_order}
                                    ref={register({
                                        required: 'This field is required',
                                        validate: (value) => { return !!value.trim() }
                                    })}
                                />
                                {errors.display_order && (<div className='invalid-feedback'>{errors.display_order.message}</div>)}
                            </div>
                            <div className='row mt-4'>
                                <div className='ml-auto'>
                                    <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseModal}>Cancel</button>
                                    <button type="submit" className="btn btn-outline-primary mr-3">Update</button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>)}

            {showUpdateOrderModal && isValueEquivalent({ _id: data._id, action: 'addUpdateFooter' }, selectedRow) && (
                <FormModal show={showUpdateOrderModal}
                    modalTitle={`Set footer of ${data.name}`}
                    openCloseModal={openCloseModal}
                >
                    <form onSubmit={handleSubmit(addUpdateFooter)} className='modal-body'>
                        <fieldset>
                            <div className='form-group'>
                                <label>Footer Text</label>
                                <input type='text'
                                    name='footer_text'
                                    onChange={onchangeFooter}
                                    className='form-control'
                                    value={footerText}
                                />
                            </div>
                            <div className='row mt-4'>
                                <div className='ml-auto'>
                                    <button type="button" className="btn btn-outline-secondary mr-2" data-dismiss="modal" onClick={openCloseModal}>Cancel</button>
                                    <button type="submit" className="btn btn-outline-primary mr-3">Save</button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </FormModal>)}
        </>
    )
}


const TemplatesPage: React.FC<Props> = ({ rootStore }) => {
    const { templateStore } = rootStore

    
    const { page, searchItem, isApiError, isLoading, apiErrorMessage, templateData, fetchTemplate } = templateStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    
    

    const loadPage = (page: number) => {
        fetchTemplate(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        fetchTemplate(1, e.target.value)
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Templates
                    <Link to='/templates/add' className='ml-auto px-3'>Add Template</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'name', title: 'Name' },
                                    { name: 'display_order', title: 'Display Order' },
                                    { name: 'type', title: 'Type', component: JointIndividualTypesCell },
                                    { name: '_id', title: 'Action', component: TemplatePreviewButton }
                                ]}
                                data={templateData.page_items.map((item) => {
                                    return { ...item, page, searchItem, fetchTemplate }
                                })}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={templateData.currentPage}
                            totalPages={templateData.totalPage}
                            totalItems={templateData.totalItem}
                            itemsPerPage={templateData.itemPerPage}
                            isFiltered={templateData.isFiltered}
                            totalAllItems={templateData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(TemplatesPage)
