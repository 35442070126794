import React, { useEffect, useState } from 'react'
import { Props, SingleField } from '../../store/types.d'
import Container from '../layout/Container'
import Select from 'react-select'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import { Link, useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import classNames from 'classnames'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Cell } from '../table/TableCellText.d'
import _ from 'lodash'
import Modal from '../commonComponent/Modal'
import { initialField } from '../../store/InitialValues'

const ManageTemplateField: React.FC<Props> = ({ rootStore }) => {

    const { fieldStore, templateFieldStore } = rootStore
    const { allFieldsName, fieldsWithValueLabel, getAllFieldsName } = fieldStore
    const { templateFieldData, getTemplateField, isLoading, addTemplateField, updateTemplateField, deleteField} = templateFieldStore

    const [displayOrder, setDisplayOrder] = useState<number>(1)
    const { control, register, errors, handleSubmit, setValue } = useForm()
    const history = useHistory()
    const pathArrayWithId = history.location.pathname.split('/')
    const templateId = pathArrayWithId[4]

    let filteredOption = fieldsWithValueLabel

    if (fieldsWithValueLabel.length !== 0 && templateFieldData.template_fields.length !== 0) {
        filteredOption = fieldsWithValueLabel.filter((item) => !templateFieldData.template_fields.filter((index) => {
            if (index.field_id === item.value) {
                return true
            } else {
                return false
            }
        }).length)
    }

    const [showDeleteWarning, setShowDeleteWarning] = useState(false)
    const [selectedRow, setSelectedRow] = useState({ _id: '', action: '' })
  
    useEffect(() => {

        if (templateFieldData.template_id !== templateId) {

            getTemplateField(templateId)
        }
        getAllFieldsName()
        if (templateFieldData.template_fields.length !== 0) {
            setDisplayOrder(templateFieldData.template_fields[templateFieldData.template_fields.length - 1].display_order + 1)
        } else if (templateFieldData.template_fields.length === 0) {
            setDisplayOrder(1)
        }
    }, [getTemplateField, templateId, getAllFieldsName, templateFieldData])

    const handleNumber = (e: any) => {
        setDisplayOrder(e.target.value)
    }

    const onSave = async (data: any) => {

        let selectedField: SingleField = initialField
        for (let i = 0; i < allFieldsName.length; i++) {
            if (allFieldsName[i]._id === data.field.value) {
                selectedField = allFieldsName[i]
            }
        }

        const requestData = {
            template_id: templateId,
            field_id: selectedField._id,
            variable_name: selectedField.variable_name,
            display_order: data.display_order
        }

        const response = await addTemplateField(requestData)
        if (response === 'true') {
            const listAPIResponse = await getTemplateField(templateId)
            if (listAPIResponse === 'true' && templateFieldData.template_fields.length !== 0) {
                setDisplayOrder(templateFieldData.template_fields[templateFieldData.template_fields.length - 1].display_order + 1)
            }
            setValue('field', null)
        } else {
            history.push('/templates')
        }
    }

    const dragDrop = async (dragIndex: number, dropIndex: number) => {
        const response = await updateTemplateField(dragIndex, dropIndex)
        if (response === 'true') {
            await getTemplateField(templateId)
        }
    }
    const openCloseDeleteWarning = () => {
        setShowDeleteWarning(!showDeleteWarning)
    }

    const onClickDelete = (action: string, data: any) => {
        if (action === 'deleteFieldFromTemplate') {
            setSelectedRow({_id: data.field_id, action:'delete'})
            openCloseDeleteWarning()
        }
    }

    const isValueEquivalent = (a: any, b: any) => {
        if (!_.isEqual(a, b)) {
            return false
        }
        return true
    }

    const onSaveModal = async () => {
        deleteField({ field_id: selectedRow._id, template_id: templateId })
        await getTemplateField(templateId)
        openCloseDeleteWarning()
    }

    const DeleteFieldFromTemplate: React.FC<Cell> = (props) => {
        let value = props.value || ''
        const data = props.data || {}
   
        // Convert boolean values to string so that they are visible in table
        if (value === true || value === false) {
            value = value.toString()
        }

        return (
            <>
                <Link to='#' className='text-danger' onClick={() => { onClickDelete('deleteFieldFromTemplate', data) }} title='Delete Field From Template'> Delete</Link>

                {showDeleteWarning && isValueEquivalent({ _id: data.field_id, action: 'delete' }, selectedRow) ? (
                    <Modal show={showDeleteWarning} modalTitle='Confirm Delete' openCloseModal=
                        {openCloseDeleteWarning} onSaveModal={onSaveModal} saveButtonName='Delete' modalDialogClass='modal-dialog-centered' >
                        <div className='text-wrap'>
              Are you sure you want to Delete this field from template?
                        </div>
                    </Modal>
                ) : null}
            </>
        )
    }
  
    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Templates', to: '/templates/' },
            { name: 'Manage Fields', isActive: true }
        ]} />
        {templateFieldData.template_id === templateId ?
            <div className="card">
                <div className="card-header">
          Manage Fields of {templateFieldData.name}
                    <div className='float-right'>
                        <Link to={'/templates/'} className='btn btn-sm btn-outline-primary'>Back</Link>
                    </div>
                </div>
                <div className="card-body">
                    <div className='card'>
                        <div className="card-header">
              Add Field To {templateFieldData.name}
                        </div>
                        <div className='card-body '>
                            <form onSubmit={handleSubmit(onSave)} >
                                <fieldset>
                                    <div className='row'>
                                        <div className='col col-md-3'>
                                            <div className='form-group'>
                                                <label>Field</label>

                                                <Controller
                                                    control={control}
                                                    name="field"
                                                    defaultValue={null}
                                                    render={({ onChange, ref, value }) => (
                                                        <Select
                                                            inputRef={ref}
                                                            options={filteredOption}
                                                            value={value}
                                                            className={(errors.field) ? 'is-invalid' : ''}
                                                            onChange={val => {
                                                                onChange(val)
                                                            }}
                                                        />)}
                                                    rules={{
                                                        validate: (value) => (value === null) ? 'Please select field' : true
                                                    }}
                                                />
                                                {(errors.field) && (<div className='invalid-feedback'>Please select field</div>)}
                                            </div>
                                        </div>
                                        <div className='col col-md-3'>
                                            <div className='form-group'>
                                                <label>Display Order</label>
                                                <input className={classNames('form-control', { 'is-invalid': errors.display_order })} type='number' ref={register({
                                                    required: 'This field is required'
                                                })} name='display_order' value={displayOrder} min={1} onChange={handleNumber} />
                                                {errors.display_order && (<div className='invalid-feedback'>{errors.display_order.message}</div>)}
                                            </div>
                                        </div>
                                        <div className='col col-md-3 pt-4'>
                                            <div className='form-group'>
                                                <button type='submit' className='btn btn-outline-primary mt-2' disabled={isLoading} >Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>

                    <div className='card mt-4'>
                        <div className="card-header">
                        Field List of {templateFieldData.name}
                        </div>
                        <div className='card-body '>
                            <div className='mt-4 table-responsive'>
                                <Table
                                    isLoading={isLoading}
                                    unique_key='_id'
                                    columns={[
                                        { name: 'name', title: 'Name' },
                                        { name: 'type', title: 'Type' },
                                        { name: 'default_value', title: 'Default Value' },
                                        { name: 'variable_name', title: 'Variable Name' },
                                        { name: 'display_order', title: 'Display Order' },
                                        { name: '', title: 'Action', component: DeleteFieldFromTemplate }
                                    ]}
                                    data={templateFieldData.template_fields}
                                    dragDrop={dragDrop}
                                    draggable={showDeleteWarning ? false : true}
                                ></Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
    </Container >
}

export default observer(ManageTemplateField)

