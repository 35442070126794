import React from 'react'

interface Props {
  modalTitle: string
  show: boolean
  saveButtonName?: string
  modalDialogClass?: string
  openCloseModal?: () => void
  onSaveModal?: () => void
  isScrollable?: boolean,
  cancelButton?: boolean,
  isApiError?: boolean,
  apiErrorMsg?: string,
  isLoading?: boolean,
  isSaveButton?: boolean,
  cancelButtonName?: string
  includeHeader?: boolean
}
const Modal: React.FC<Props> = (props) => {
    const defaultModalDialogClass = 'modal-dialog modal-dialog-scrollable '
    const showModal = props.show
    const modalTitle = props.modalTitle
    const includeHeader = props.includeHeader
    const saveButtonName = props.saveButtonName || 'Save changes'
    const cancelButtonName = props.cancelButtonName || 'Cancel'
    const isSaveButton = (props.isSaveButton === undefined) ? true : props.isSaveButton

    const openCloseModal = props.openCloseModal
    const onSaveModal = props.onSaveModal
    const modalDialogClass = (props.modalDialogClass === undefined) ? defaultModalDialogClass : defaultModalDialogClass + props.modalDialogClass
    const isScrollable = props.isScrollable
    const showCancelButton = (props.cancelButton === undefined) ? true : props.cancelButton
    
    const styles = showModal ? { display: 'block' } : { display: 'none' }
    const modalClass = showModal ? 'modal fade in show' : 'modal in fade'
    const backDropStyle = showModal ? { opacity: .3 } : { display: 'none' }
    const scrollableBodyStyle = isScrollable === false ? 'modal-body resource' : 'modal-body'

    return (
        <>
            {showModal && <div className='modal-backdrop' style={backDropStyle}></div>}
            <div id="myModal" className={modalClass} role="dialog" style={styles}>
                <div className={modalDialogClass}>
                    <div className="modal-content">
                        <div className="modal-header">
                            {includeHeader ? <h5 className='mb-0 text-wrap'>{modalTitle}</h5> : <div className='modal-title'> {modalTitle} </div> }
                            <button type="button" className="close" onClick={openCloseModal} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className={scrollableBodyStyle} >
                            {props.isApiError && !props.isLoading && <div className='responsive alert-danger p-3 mb-4 rounded' > {props.apiErrorMsg} </div>}
                            {props.children}
                        </div>
                        <div className='modal-footer'>
                            {showCancelButton && <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseModal}>{cancelButtonName}</button>}
                            {isSaveButton === true && <button type="submit" className="btn btn-outline-primary" onClick={onSaveModal}>{saveButtonName}</button>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal