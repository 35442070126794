import { observable } from 'mobx'
import RootStore from './Root'
import {addUpdatePlural, getPlurals, getSinglePluralDetails} from '../api/PluralAction'
import { PluralInformation, Dictionary } from './types.d'
import { initialTableDataWithPaginationDetails, initialSinglePlural } from './InitialValues'

interface PluralData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<PluralInformation>
}

class PluralStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public pluralDetails: PluralInformation
    @observable public pluralData: PluralData
    @observable public searchItem: string
    @observable public page: number
    @observable public pluralDataHistory: Array<PluralData>

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.searchItem = ''
        this.page = 1
        this.pluralDetails = initialSinglePlural
        this.pluralDataHistory = []
        if (rootStore.authStore.isAdmin) {
            this.fetchPlurals(1, '', '')
        }
        this.pluralData = initialTableDataWithPaginationDetails
    }

    resetPluralDetails = (): void => {
        this.pluralDetails = {
            _id: '',
            singular: '',
            plural: ''
        }
    } 

    //get the list of plurals with details
    fetchPlurals = (page: number, searchItem: string, action: string): void => {
        if (action === 'change_page') {
            this.page = page
            for (let i = 0; i < this.pluralDataHistory.length; i++) {
                if (this.pluralDataHistory[i].currentPage === page) {
                    this.pluralDataHistory.push(this.pluralData)
                    this.pluralData = this.pluralDataHistory[i]
                    return
                }
            }
            this.pluralDataHistory.push(this.pluralData)

        } else if (action === 'search_item') {
            this.pluralDataHistory = []
            this.searchItem = searchItem
            this.page = 1

        } else if(action === 'update' || action === 'add') {
            this.pluralDataHistory = []
        }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem }

        getPlurals(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.pluralData = response.data.data
            this.pluralDetails = initialSinglePlural
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the plural details in pluralDetails variable for show in update plural page
    setPluralDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSinglePluralDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.pluralDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update or delete the plural details of given plural_id
    addUpdateSinglePlural = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            addUpdatePlural(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchPlurals(this.page, this.searchItem, 'update')
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }
}

export default PluralStore
