import { observable } from 'mobx'
import RootStore from './Root'
import { getAppUsers, getAppUserDetails, updateAppUser, getAllAppUserForInternalUse } from '../api/AppUserActions'
import { initialTableDataWithPaginationDetails } from './InitialValues'

interface AppUserInformation {
    _id?: string,
    first_name: string,
    last_name: string,
    email: string,
    role: string,
    status: string,
    account_code: string
    attorney_code: string
    user_specific_price_template_id: string
    engagement_template_id: string
    user_associated_tags: Array<string>
}

interface AppUserData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<AppUserInformation>
}

interface Dictionary<T> {
    // eslint-disable-next-line
    [Key: string]: T | any
}

class AppUserStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public appUserDetails: AppUserInformation
    @observable public appUserData: AppUserData
    @observable public searchItem: string
    @observable public searchByRoleFilter: string
    @observable public page: number
    @observable public appUserDataHistory: Array<AppUserData>
    @observable public allAppUsers: Array<AppUserInformation>
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.searchByRoleFilter = 'ALL'
        this.page = 1
        this.appUserDetails = {
            _id: '',
            first_name: '',
            last_name: '',
            email: '',
            role: '',
            status: '',
            account_code: '',
            attorney_code: '',
            user_specific_price_template_id: '',
            engagement_template_id: '',
            user_associated_tags: []
        }
        this.appUserDataHistory = []
        this.appUserData = initialTableDataWithPaginationDetails
        this.allAppUsers = []
        this.fetchAllAppUsersForInternalUse()
    }

    resetAppUserDetails = ():void => {
        this.appUserDetails = {
            _id: '',
            first_name: '',
            last_name: '',
            email: '',
            role: '',
            status: '',
            account_code: '',
            attorney_code: '',
            user_specific_price_template_id: '',
            engagement_template_id: '',
            user_associated_tags: []
        }
    }

    //get the list of App Users with details
    fetchAppUser = (page: number, searchItem: string, action: string, searchFromRole: string): void => {
        this.isPageOpened = true
        if (action === 'change_page') {
            this.page = page
            
            for (let i = 0; i < this.appUserDataHistory.length; i++) {
                if (this.appUserDataHistory[i].currentPage === page) {
                    this.appUserDataHistory.push(this.appUserData)
                    this.appUserData = this.appUserDataHistory[i]
                    return
                }
            }
            this.appUserDataHistory.push(this.appUserData)
        } else if (action === 'search_item') {
            this.appUserDataHistory = []
            this.searchItem = searchItem
            this.page = 1
        } else if(action === 'update' || action === 'add') {
            this.appUserDataHistory = []
        } else if (action === 'change_filter') {
            this.appUserDataHistory = []
            this.searchByRoleFilter = searchFromRole
            this.searchItem = searchItem
            this.page = page
        }

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem, search_from_role: this.searchByRoleFilter }

        getAppUsers(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.resetAppUserDetails()
            this.appUserData = response.data.data
            this.appUserData = response.data.data
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the app user details in appUserDetails variable for show in update App User page
    setAppUserDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getAppUserDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.appUserDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update the appUser details of given appUser_id
    updateAppUserDetails = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            updateAppUser(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }

                this.fetchAppUser(this.page, this.searchItem, 'update', this.searchByRoleFilter)
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    fetchAllAppUsersForInternalUse = (): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getAllAppUserForInternalUse().then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.allAppUsers = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default AppUserStore
