import { observable } from 'mobx'
import RootStore from './Root'
import { getErrorLogs, getErrorLogsRequestData, getErrorLogsResponseData } from '../api/ErrorLogAction'
import { ErrorLogCronInformation } from './types.d'
import { initialTableDataWithPaginationDetails, initialErrorLogCronData } from './InitialValues'

interface ErrorLogData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    totalAllItems: number,
    page_items: Array<ErrorLogCronInformation>
}

class ErrorLogStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public errorLogCronDetails: ErrorLogCronInformation
    @observable public errorLogCronData: ErrorLogData
    @observable public searchItem: string
    @observable public page: number
    @observable public requestData: any
    @observable public responseData: any
    @observable public isLoadingInfo: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.searchItem = ''
        this.page = 1
        this.errorLogCronDetails = initialErrorLogCronData
        this.isLoadingInfo = false
        if (rootStore.authStore.isAdmin) {
            this.fetchErrorLogCronData(1)
        }
        this.errorLogCronData = initialTableDataWithPaginationDetails
    }


    //get the list of plurals with details
    fetchErrorLogCronData = (page: number, searchItem = ''): void => {
        
        this.searchItem = searchItem
        this.page = page
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem }

        getErrorLogs(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.errorLogCronData = response.data.data
            this.errorLogCronDetails = initialErrorLogCronData
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
    
    getRequestData = (_id: string): void => {
        this.isLoadingInfo = true
        this.isApiError = false
        this.apiErrorMessage = ''
        getErrorLogsRequestData(_id).then((response)=>{
            this.isLoadingInfo = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.requestData = response.data.data[0]
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoadingInfo = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    getResponseData = (_id: string): void => {
        this.isLoadingInfo = true
        this.isApiError = false
        this.apiErrorMessage = ''
        getErrorLogsResponseData(_id).then((response)=>{
            this.isLoadingInfo = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.responseData = response.data.data[0].response_data[0]
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoadingInfo = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

}

export default ErrorLogStore
