import React, { useEffect, useState } from 'react'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { Cell } from '../../table/TableCellText.d'
import { Props } from '../../../store/types.d'
import { FormateDate } from '../../commonComponent/commonFuction'


export const AudioPlayer: React.FC<Cell> = (props) => {
    const value = props.value || ''

    return (
        <audio preload="none" controls>
            <source src={value} type="audio/mpeg"/>
        </audio>
    )
}

const AudioListPage: React.FC<Props> = ({ rootStore }) => {
    const { audioStore } = rootStore
    const { audioInformationData,  fetchAudioDetails, searchItem, isApiError, isLoading, apiErrorMessage, isPageOpened } = audioStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    useEffect(() => {
        if (localStorage.getItem('userRole') === 'SUPER_ADMIN' && !isPageOpened) {
            fetchAudioDetails(1, '')
        }
    }, [])

    const loadPage = (page: number) => {
        fetchAudioDetails(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        fetchAudioDetails(1, e.target.value.trim())
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col'><h3>Vision Recordings</h3></div>
                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>

                        {((isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{apiErrorMessage}</div>)}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'probable_name', title: 'Vision Name' },
                                    { name: 'added_by', title: 'Added By' },
                                    { name: 'audio', title: 'Recording', component: AudioPlayer },
                                    { name: 'audio_length', title: 'Length (in sec)' },
                                    { name: 'createdAt', title: 'Created At', component: FormateDate },
                                ]}
                                data={audioInformationData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={audioInformationData.currentPage}
                            totalPages={audioInformationData.totalPage}
                            totalItems={audioInformationData.totalItem}
                            itemsPerPage={audioInformationData.itemPerPage}
                            isFiltered={audioInformationData.isFiltered}
                            totalAllItems={audioInformationData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AudioListPage)
