import React, { useEffect } from 'react'
import { Props } from '../../store/types.d'
import Container from '../layout/Container'
import { Article } from '../commonComponent/DotLoader'
import { replaceSpaceWithHyphen } from '../commonComponent/commonFuction'
import { observer } from 'mobx-react'
import parse from 'html-react-parser'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Preview } from '../commonComponent/PreviewStyle'
import uuid from 'react-uuid'


const DocumentPreview: React.FC<Props> = ({ rootStore }) => {

    const { clientStore, templateStore } = rootStore
    const { isLoading, setPreviewDocument, isApiError, apiErrorMessage, previewDocument, setCurrentMatter } = clientStore
    const { getOneTemplateDetails } = templateStore
    const { clientId, matterId, documentId, spouse, UUID } = useParams<{
        clientId: string,
        matterId: string,
        documentId: string,
        spouse: string,
        UUID: string
    }>()

    useEffect(() => {
        if (previewDocument._id === '' || previewDocument._id !== documentId) {
            const forSpouse = (spouse === 'true') ? true : false
            setCurrentMatter(matterId)
            getOneTemplateDetails(documentId, matterId)
            setPreviewDocument(matterId, documentId, forSpouse, UUID)
        }
    }, [documentId, getOneTemplateDetails, matterId, spouse, UUID, previewDocument, setPreviewDocument, setCurrentMatter])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>

        <Breadcrumb links={[
            { name: 'Clients', to: '/clients/' },
            { name: 'Matters', to: `/clients/${clientId}` },
            { name: 'Documents', to: `/clients/${clientId}/matters/${matterId}` },
            { name: 'Preview', isActive: true }
        ]} />
        <Preview>
            <div>
                <div className='card' style={{ width: '794px', padding: '60px 84px 60px 84px' }}>
                    <div>
                        <div >
                            {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                            {isLoading ? <Article /> : (previewDocument.content) ? previewDocument.content.map((item: any) => {
                                if (item.type === 'article') {
                                    return <>
                                        {(item.content) ? item.content.map((article: any) => {
                                            if (article.type === 'paragraph') {
                                                return (article.body) ? <div key={uuid()} id={replaceSpaceWithHyphen(article.name)}> {parse(article.body)} </div> : null
                                            } else if (article.type === 'section') {
                                                return <>
                                                    {(article.content) ? article.content.map((paraInSection: any) => {
                                                        return (paraInSection.body) ? <div key={uuid()} id={replaceSpaceWithHyphen(paraInSection.name)}> {parse(paraInSection.body)} </div> : null
                                                    }) : null}
                                                </>
                                            } else {
                                                return null
                                            }
                                        }) : null}
                                    </>
                                } else if (item.type === 'paragraph') {
                                    return <div key={uuid()} id={replaceSpaceWithHyphen(item.name)}> {parse(item.body)} </div>
                                } else {
                                    return null
                                }
                            }) : null}
                        </div>
                    </div>
                </div>
            </div>
        </Preview>
    </Container>
}

export default observer(DocumentPreview)

