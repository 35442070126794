import React, { useEffect, useState } from 'react'
import MonacoEditor, { useMonaco } from '@monaco-editor/react'
import RenderRawHtml from './RenderRawHtml'
import MonacoEditorStore from '../../store/MonacoEditorStore'
import { BtnBold, BtnItalic, BtnUndo, BtnRedo, Separator, BtnUnderline, BtnNumberedList, BtnBulletList, BtnClearFormatting, HtmlButton, DefaultEditor, EditorProvider, Toolbar, Editor } from 'react-simple-wysiwyg'

interface Props {
    content?: string
    onEditorChange: (data: any) => void
    label?: string,
    store?: MonacoEditorStore,
    isSimpleEditor?: boolean
}

const ReactEditor: React.FC<Props> = (props) => {
    const label = props.label || 'Body'
    const editor_content = props.content ? props.content : ''
    const [content, setContent] = useState(editor_content)
    const { store } = props
    const isSimpleEditor = (props.isSimpleEditor !== undefined) ? props.isSimpleEditor : false

    const monaco = useMonaco()

    useEffect(() => {
        if (monaco) {
            store?.setupMonacoEditor(monaco)
        }
    }, [monaco, store])

    const onContentChange = (text) => {
        if (isSimpleEditor) {
            setContent(text.target.value)
            props.onEditorChange(text.target.value)
        } else {
            setContent(text)
            props.onEditorChange(text)
        }

    }

    return <>
        <label className='mb-0 mt-2'>{label}</label>
        {!isSimpleEditor ? <div className='card form-group'>
            <MonacoEditor
                height="350px"
                language='oath'
                defaultValue={editor_content}
                onChange={onContentChange}
                theme="oathTheme"
                options={{
                    minimap: {
                        enabled: false
                    },
                    wordWrap: 'wordWrapColumn',
                    wordWrapColumn: 116,
                    wrappingIndent: 'same',
                    'bracketPairColorization.enabled': true,
                    'semanticHighlighting.enabled': true,
                    fontSize: '14px'
                }}
            /></div> : <div className='card form-group' style={{ width: 'auto', padding: '60px 84px 30px 84px', margin: '0px 148.8px' }}><EditorProvider>
            <Editor value={content} onChange={onContentChange}>
                <Toolbar>
                    <BtnUndo />
                    <BtnRedo />
                    <Separator />
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <Separator />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <Separator />
                    <BtnClearFormatting />
                    <HtmlButton />
                </Toolbar>
            </Editor>
        </EditorProvider>
        </div>}
        <label className='mb-0 mt-2'>Preview</label>
        <div className='card form-group' style={{ width: 'auto', padding: '60px 84px 30px 84px', margin: '0px 148.8px' }}>
            <RenderRawHtml>{content}</RenderRawHtml>
        </div>
    </>
}

export default ReactEditor