import React, { useState } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { addTemplate } from '../../api/TemplateActions'
import InputField from '../commonComponent/InputFieldComponent'
import classNames from 'classnames'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { templateTypes } from './TemplateTypes'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const AddTemplatePage: React.FC<Props> = ({ rootStore }) => {
    const [apiErrorMessage, setApiErrorMessage] = useState('')
    const [isApiError, setIsApiError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [type, setType] = useState({ value: 'BOTH', label: 'Both' })
    const { templateStore } = rootStore
    const { fetchTemplate } = templateStore

    const { register, handleSubmit, errors } = useForm()
    const history = useHistory()

    const handleClick = () => {
        history.push('/templates/')
    }

    const handleOnChangeType = (data: any) => {
        setType(data)
    }

    const onSubmit = (data: Dictionary<string>) => {

        setIsLoading(true)
        const requestData = {
            name: data.name,
            states: [],
            is_all_states: true,
            display_order: data.display_order,
            type: type.value,
            should_create_document_for_both: (type.value !== 'INDIVIDUAL') ? data.should_create_document_for_both : false,
            enable_multiple_document_mode: data.enable_multiple_document_mode,
            should_lock_interview: data.should_lock_interview,
            table_of_contents: data.table_of_contents,
            reset_page_number_per_article: data.reset_page_number_per_article
        }

        addTemplate(requestData).then((response) => {
            setIsLoading(false)

            if (response.data.success === 0) {
                setIsApiError(true)
                setApiErrorMessage(response.data.message)
            } else {
                history.push('/templates/')
                fetchTemplate(1, '')
            }

        }).catch((e) => {
            setIsLoading(false)
            setIsApiError(true)
            setApiErrorMessage(e.message)
        })
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Templates', to: '/templates/' },
            { name: 'Add', isActive: true }
        ]} />
        <div className="card">
            <div className="card-header">
        Add Template
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>

                                <InputField
                                    label='Name *'
                                    fieldName='name'
                                    register={register}
                                    errors={errors.name}
                                    placeholder='Enter Name'
                                    required={true}
                                />

                                <div className="form-group">
                                    <label>Type *</label>
                                    <ReactSelectComponent
                                        options={templateTypes}
                                        value={type}
                                        handleOnChangeReact={handleOnChangeType}
                                        helpText='This is type of template. Used for differentiate templates in matter add/update. This is not editable. Please select carefully.'
                                    />
                                </div>

                                <div className={classNames({ 'd-none': (type.value !== 'BOTH' && type.value !== 'JOINT') })}>
                                    <div className="custom-control form-group mt-1 custom-switch">
                                        <input type="checkbox" defaultChecked={false}
                                            ref={register}
                                            className="custom-control-input" name='should_create_document_for_both' id="shouldCreateDocumentForBoth" />
                                        <label className="custom-control-label" htmlFor='shouldCreateDocumentForBoth'>Should Create Document For Both</label>
                                        {errors.should_create_document_for_both ? (<div className='invalid-feedback'>{ errors.should_create_document_for_both.message }</div>) : <small className='form-text text-muted switch-help-text'>It is used to decide the template is used for both as spouse and client at preview in CMS. This is not editable. Please select carefully.</small>}
                                    </div>
                                </div>

                                <div className="custom-control form-group mt-1 custom-switch">
                                    <input type="checkbox" defaultChecked={false}
                                        ref={register}
                                        className="custom-control-input" name='enable_multiple_document_mode' id="enableMultipleDocumentMode" />
                                    <label className="custom-control-label" htmlFor='enableMultipleDocumentMode'>Enable Multiple Document Mode</label>
                                    {errors.enable_multiple_document_mode ? (<div className='invalid-feedback'>{ errors.enable_multiple_document_mode.message }</div>) : <small className='form-text text-muted switch-help-text'> This is not editable. Please select carefully.</small>}
                                </div>

                                <div className="custom-control form-group mt-1 custom-switch">
                                    <input type="checkbox" defaultChecked={true}
                                        ref={register}
                                        className="custom-control-input" name='should_lock_interview' id="shouldLockInterview" />
                                    <label className="custom-control-label" htmlFor='shouldLockInterview'>Should Lock interview</label>
                                    {errors.should_lock_interview ? (<div className='invalid-feedback'>{ errors.should_lock_interview.message }</div>) : <small className='form-text text-muted switch-help-text'>On hand edited action in this document interview should locked. This is not editable. Please select carefully.</small>}
                                </div>

                                <div className="custom-control form-group mt-1 custom-switch">
                                    <input type="checkbox" defaultChecked={false}
                                        ref={register}
                                        className="custom-control-input" name='table_of_contents' id="tableOfContents" />
                                    <label className="custom-control-label" htmlFor='tableOfContents'>Enable Table Of Contents</label>
                                    {errors.table_of_contents ? (<div className='invalid-feedback'>{ errors.table_of_contents.message }</div>) : <small className='form-text text-muted switch-help-text'>On download document do you want to file of Table of contents? This is not editable. Please select carefully.</small>}
                                </div>

                                <div className="custom-control form-group mt-1 custom-switch">
                                    <input type="checkbox" defaultChecked={false}
                                        ref={register}
                                        className="custom-control-input" name='reset_page_number_per_article' id="tableOfContents" />
                                    <label className="custom-control-label" htmlFor='tableOfContents'>Reset Page Number Of Each Article</label>
                                    {errors.reset_page_number_per_article ? (<div className='invalid-feedback'>{ errors.reset_page_number_per_article.message }</div>) : <small className='form-text text-muted switch-help-text'>On download document do you want page number reset on each article ? This is not editable. Please select carefully.</small>}
                                </div>

                                <div className="form-group">
                                    <label>Display Order</label>
                                    <input
                                        ref={register({
                                            required: 'This field is required'
                                        })}
                                        name='display_order'
                                        type='number'
                                        min={1}
                                        className={classNames('form-control', { 'is-invalid': errors.display_order })}
                                        defaultValue={1}
                                    />
                                    {errors.display_order && (<div className='invalid-feedback'>{errors.display_order.message}</div>)}
                                </div>

                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddTemplatePage)