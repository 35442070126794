import { observable } from 'mobx'
import RootStore from './Root'
import { FieldsVarNameDescription, MonacoEditor } from './types.d'

class MonacoEditorStore implements MonacoEditor {
    public rootStore: RootStore
    @observable public isInitialized = false
    private keywords: Array<string>

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.keywords = []
    }

    loadKeywords(): any {
        this.keywords = this.rootStore.fieldStore.fieldsWithVariableDescription.map((list: FieldsVarNameDescription) => {
            return list.variable_name
        })
    }
    async setupMonacoEditor(monaco: any): Promise<any> {
        if (this.isInitialized === true) {
            return
        }
        this.isInitialized = true

        monaco.languages.register({ id: 'oath' })

        const languageConfiguration = {
            brackets: [
                ['{%', '%}', 'delimiter.nunjucks'],
                ['{{', '}}', 'delimiter.double'],
                ['{', '}', 'delimiter.curly'],
                ['[', ']', 'delimiter.square'],
                ['(', ')', 'delimiter.parenthesis'],
                ['<', '>', 'delimiter.angle']
            ],
            surroundingPairs: [
                { open: '{%', close: '%}' },
                { open: '{', close: '}' },
                { open: '[', close: ']' },
                { open: '(', close: ')' },
                { open: '<', close: '>' },
                { open: '\'', close: '\'' },
                { open: '"', close: '"' },
            ],
            autoClosingPairs: [
                { open: '{%', close: '%}' },
                { open: '{', close: '}' },
                { open: '[', close: ']' },
                { open: '(', close: ')' },
                { open: '\'', close: '\'', notIn: ['string', 'comment'] },
                { open: '"', close: '"', notIn: ['string', 'comment'] },
            ]
        }

        monaco.languages.setLanguageConfiguration('oath', languageConfiguration)

        this.loadKeywords()

        monaco.languages.setMonarchTokensProvider('oath', {
            keywords: this.keywords,
            tokenizer: {
                root: [
                    [/[A-Za-z_$][\w$]*/, {
                        cases: {
                            '@keywords': 'keyword',
                            '@default': 'identifier'
                        }
                    }],
                    [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],
                    [/<\/?\w+>?/, 'html.tag'],
                    [/'[a-zA-Z_]+'/, 'single.quote']
                ],
                string: [
                    [/[^\\"]+/, 'string'],
                    [/\\./, 'string.escape.invalid'],
                    [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }]
                ]
            }
        })

        const functionNamesWithDescription = [
            { variable_name: 'is_trust', description: 'Checks if the variable is Trust'},
            { variable_name: 'is_entity', description: 'Checks if the variable is Entity' },
            { variable_name: 'is_charity', description: 'Checks if the variable is Charity' },
            { variable_name: 'is_individual', description: 'Checks if the variable is Individual' },
            { variable_name: 'is_contact', description: 'Checks if the variable is Contact, Means it could be Entity, Charity or Individual' },
            { variable_name: 'get_name', description: 'Get the name of Trust, Entity, Charity or Individual' },
        ]

        const fieldsWithVariableDescription = this.rootStore.fieldStore.fieldsWithVariableDescription.concat(functionNamesWithDescription)
        
        monaco.languages.registerHoverProvider('oath', {
            provideHover: function (model, position) {
                const word = model.getWordAtPosition(position)
                if (!word) { return }
                const foundItem = fieldsWithVariableDescription.find((item) => {
                    return item.variable_name === word.word
                })
                if (foundItem) {
                    return {
                        range: new monaco.Range(
                            position.lineNumber,
                            word.startColumn,
                            position.lineNumber,
                            word.endColumn
                        ),
                        contents: [
                            { value: foundItem.variable_name },
                            { value: '```html\n' + foundItem.description + '\n```' }
                        ]
                    }
                }
            }
        })

        monaco.languages.registerCompletionItemProvider('oath', {
            triggerCharacters: ['>'],
            provideCompletionItems: (model, position) => {
                const codePre: string = model.getValueInRange({
                    startLineNumber: position.lineNumber,
                    startColumn: 1,
                    endLineNumber: position.lineNumber,
                    endColumn: position.column,
                })

                const tag = codePre.match(/.*<(\w+)>$/)?.[1]

                if (!tag) {
                    return
                }

                const word = model.getWordUntilPosition(position)

                return {
                    suggestions: [
                        {
                            label: `</${tag}>`,
                            kind: monaco.languages.CompletionItemKind.EnumMember,
                            insertText: `$1</${tag}>`,
                            insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                            range: {
                                startLineNumber: position.lineNumber,
                                endLineNumber: position.lineNumber,
                                startColumn: word.startColumn,
                                endColumn: word.endColumn,
                            },
                        },
                    ],
                }
            },
        })

        function createDependencyProposals(range) {
            return fieldsWithVariableDescription.map((item: FieldsVarNameDescription) => {
                return {
                    label: item.variable_name,
                    kind: monaco.languages.CompletionItemKind.Variable,
                    documentation: `${item.description}`,
                    insertText: item.variable_name,
                    range: range
                }
            })
        }

        monaco.languages.registerCompletionItemProvider('oath', {
            provideCompletionItems: function (model, position) {
                // // find out if we are completing a property in the 'dependencies' object.
                // var textUntilPosition = model.getValueInRange({
                //     startLineNumber: 1,
                //     startColumn: 1,
                //     endLineNumber: position.lineNumber,
                //     endColumn: position.column
                // });
                // var match = textUntilPosition.match(
                //     /"dependencies"\s*:\s*\{\s*("[^"]*"\s*:\s*"[^"]*"\s*,\s*)*([^"]*)?$/
                // );
                // if (!match) {
                //     return { suggestions: [] };
                // }
                const word = model.getWordUntilPosition(position)
                const range = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn
                }
                return {
                    suggestions: createDependencyProposals(range)
                }
            }
        })

        const constants = [
            'undefined',
            'false',
            'true'
        ]

        const nunjucks_keywords = [
            'if',
            'elif',
            'else',
            'endif',
            'for',
            'endfor',
            'length',
            'upper'
        ]
        const legend = {
            tokenTypes: [
                'globalSequenceFormat1',
                'globalSequenceFormat2',
                'globalSequenceFormat3',
                'pluralManage',
                'commonParagraph',
                'TableFormatter',
                'numberToWords',
                'formateDate',
                'getDropdownAnswer',
                'getOrangeLine',
                'headerForPortfolio',
                'utility',
                'is_trust',
                'is_entity',
                'is_individual',
                'is_contact',
                'get_name',
            ].concat(nunjucks_keywords).concat(constants),
            tokenModifiers: []
        }

        function getType(type) {
            return legend.tokenTypes.indexOf(type)
        }

        function getModifier(modifiers) {
            if (typeof modifiers === 'string') {
                modifiers = [modifiers]
            }
            if (Array.isArray(modifiers)) {
                let nModifiers = 0
                for (const modifier of modifiers) {
                    const nModifier = legend.tokenModifiers.indexOf(modifier)
                    if (nModifier > -1) {
                        nModifiers |= (1 << nModifier) >>> 0
                    }
                }
                return nModifiers
            } else {
                return 0
            }
        }

        const tokenPattern = new RegExp('([a-zA-Z0-9_]+)((?:\\.[a-zA-Z0-9_]+)*)', 'g')

        monaco.languages.registerDocumentSemanticTokensProvider('oath', {
            getLegend: function () {
                return legend
            },
            provideDocumentSemanticTokens: function (model) {
                const lines = model.getLinesContent()

                /** @type {number[]} */
                const data = []

                let prevLine = 0
                let prevChar = 0

                for (let i = 0; i < lines.length; i++) {
                    const line = lines[i]

                    for (let match = null; (match = tokenPattern.exec(line));) {
                        // translate token and modifiers to number representations
                        const type = getType(match[1])
                        if (type === -1) {
                            continue
                        }
                        const modifier = match[2].length ? getModifier(match[2].split('.').slice(1)) : 0

                        data.push(
                            // translate line to deltaLine
                            i - prevLine,
                            // for the same line, translate start to deltaStart
                            prevLine === i ? match.index - prevChar : match.index,
                            match[0].length,
                            type,
                            modifier
                        )

                        prevLine = i
                        prevChar = match.index
                    }
                }
                return {
                    data: new Uint32Array(data),
                    resultId: null
                }
            },
            releaseDocumentSemanticTokens: function () { return }
        })

        monaco.editor.defineTheme('oathTheme', {
            base: 'vs',
            inherit: true,
            rules: [
                { token: 'keyword', foreground: '008000' },
                { token: 'typeKeyword', foreground: 'fd971f' },
                { token: 'string', foreground: 'a31515' },
                { token: 'html.tag', foreground: '800000' },
                { token: 'single.quote', foreground: '800000' },
                { token: 'nunjucks.keywords', foreground: '800000' }
            ].concat(legend.tokenTypes.map((item) => {
                return { token: item, foreground: '8b67cc' }
            }))
                .concat(nunjucks_keywords.map((item) => {
                    return { token: item, foreground: 'AF00DB' }
                })).concat(constants.map((item) => {
                    return { token: item, foreground: 'A00000' }
                })),
            colors: {
                'editor.foreground': '#000000'
            }
        })
        monaco.editor.setTheme('oathTheme')
    }
}

export default MonacoEditorStore