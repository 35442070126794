import { observable } from 'mobx'
import RootStore from './Root'
import { getFields, getFieldDetails, updateField, getAllFields, getGroupFields } from '../api/FieldActions'
import SingleFieldStore from './SingleField'
import { initialField, initialTableDataWithPaginationDetails } from './InitialValues'
import { FieldName, NameLabel, SingleField, ValueLabel, FieldsVarNameDescription } from './types.d'
import { defaultOperators } from 'react-querybuilder'

interface FieldInformation {
    _id?: string,
    name: string,
    type: string,
    default: string,
    variable_name: string
}

interface FieldData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<FieldInformation>
}

interface Dictionary<T> {
    // eslint-disable-next-line
    [Key: string]: T | any
}

class FieldStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public fieldDetails: SingleFieldStore
    @observable public fieldData: FieldData
    @observable public allFieldsName: Array<SingleField>
    @observable public fieldsWithValueLabel: Array<ValueLabel>
    @observable public fieldsNameLabel: Array<FieldName>
    @observable public fieldsWithVariableNameAndName: Array<ValueLabel> //exclude client, header, and matter type fields 
    @observable public groupFieldsWithVariableNameAndName: Array<ValueLabel>
    @observable public searchItem: string
    @observable public searchByTemplateFilter: string
    @observable public page: number
    @observable public fieldDataHistory: Array<FieldData>
    @observable public isGlobal: boolean
    @observable public isConditional: boolean
    @observable public isSubField: boolean
    @observable public isHidden: boolean
    @observable public searchByTypeFilter: string
    @observable public groupFieldsValueLabel: Array<ValueLabel>
    @observable public searchByGroupField: string
    @observable public fieldsWithVariableDescription:Array<FieldsVarNameDescription>


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.fieldDetails = new SingleFieldStore(initialField)
        this.getAllFieldsName()
        this.fieldData = initialTableDataWithPaginationDetails
        this.fieldsWithValueLabel = []
        this.fieldsNameLabel = []
        this.allFieldsName = []
        this.fieldsWithVariableNameAndName = []
        this.searchItem = ''
        this.searchByTemplateFilter = 'All'
        this.searchByTypeFilter = 'All'
        this.searchByGroupField = 'All'
        this.page = 1
        this.fieldDataHistory = []
        this.isGlobal = false
        this.isConditional = false
        this.isSubField = false
        this.isHidden = false
        if (rootStore.authStore.isAdmin) {
            this.fetchField(1, '', '', 'All', false, false, false, false, 'All', 'All')
            this.getGroupFieldList('All', false, false, false, false)
        }
        this.groupFieldsValueLabel = []
    }

    resetFieldDetails = (): void => {
        this.fieldDetails = new SingleFieldStore(initialField)
    }

    //get the list of fields with details
    fetchField = (page: number, searchItem: string, action: string, searchFromTemplate: string, isGlobal: boolean, isConditional: boolean, isSubField: boolean, isHidden: boolean, searchFromType: string, groupField: string): void => {

        if (action === 'change_page') {
            this.page = page

            for (let i = 0; i < this.fieldDataHistory.length; i++) {
                if (this.fieldDataHistory[i].currentPage === page) {
                    this.fieldDataHistory.push(this.fieldData)
                    this.fieldData = this.fieldDataHistory[i]
                    return
                }
            }
            this.fieldDataHistory.push(this.fieldData)
        } else if (action === 'search_item') {
            this.fieldDataHistory = []
            this.searchItem = searchItem
            this.page = page
        } else if (action === 'update' || action === 'add') {
            this.fieldDataHistory = []
        } else if (action === 'change_filter') {
            this.fieldDataHistory = []
            this.searchByTemplateFilter = searchFromTemplate
            this.isGlobal = isGlobal
            this.isSubField = isSubField
            this.isHidden = isHidden
            this.isConditional = isConditional
            this.searchItem = searchItem
            this.page = page
            this.searchByTypeFilter = searchFromType
            this.searchByGroupField = groupField
        }
        if (this.searchByTypeFilter === 'group') {
            this.getGroupFieldList(searchFromTemplate, isGlobal, isConditional, isSubField, isHidden)
        }

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = { page: this.page, search_item: this.searchItem, search_from_template_fields: this.searchByTemplateFilter, is_global: this.isGlobal, is_conditional: this.isConditional, is_sub_field: this.isSubField, is_hidden: this.isHidden, search_from_type_fields: this.searchByTypeFilter, search_from_group_fields: this.searchByGroupField }

        getFields(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.getAllFieldsName()
            this.isLoading = false
            this.fieldData = response.data.data
            this.resetFieldDetails()
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //get the list of group fields with details
    getGroupFieldList = (searchFromTemplate: string, isGlobal: boolean, isConditional: boolean, isSubField: boolean, isHidden: boolean): void => {

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        this.searchByTemplateFilter = searchFromTemplate
        this.isGlobal = isGlobal
        this.isSubField = isSubField
        this.isHidden = isHidden
        this.isConditional = isConditional

        const requestData = { search_from_template_fields: this.searchByTemplateFilter, is_global: this.isGlobal, is_conditional: this.isConditional, is_sub_field: this.isSubField, is_hidden: this.isHidden }

        getGroupFields(requestData).then((response) => {
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.groupFieldsValueLabel = response.data.data.map((list: any) => {
                const data = {
                    value: list.variable_name,
                    label: list.name
                }

                return data
            })
            this.groupFieldsValueLabel.splice(0, 0, { value: 'All', label: 'All' })
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    // all field name and id
    getAllFieldsName = (): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getAllFields().then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            } else {
                this.allFieldsName = response.data.data
                this.fieldsWithValueLabel = this.allFieldsName.map((list: any) => {
                    const data = {
                        value: list._id,
                        label: list.name
                    }
                    return data
                })

                const filteredFields = this.allFieldsName.filter((list: any) => list.type !== 'header' && list.type !== 'client' && list.type !== 'matter')

                this.fieldsWithVariableNameAndName = filteredFields.map((list: any) => {
                    const data = {
                        value: list.variable_name,
                        label: list.name
                    }
                    return data
                })

                const filteredGroupFields = this.allFieldsName.filter((list: any) =>  list.type === 'group')

                this.groupFieldsWithVariableNameAndName = filteredGroupFields.map((list: any) => {
                    const data = {
                        value: list.variable_name,
                        label: list.name
                    }
                    return data
                })

                this.fieldsWithVariableDescription = this.allFieldsName.map((list:any) => {
                    return {
                        variable_name: list.variable_name,
                        description: list.description
                    }
                })

                this.fieldsNameLabel = this.allFieldsName.map((list: any) => {

                    let operators = defaultOperators
                    let valueEditorType
                    let inputType = 'text'
                    let values: Array<NameLabel> = []
                    let defaultValue = list.default_value


                    if (list.type === 'radio') {
                        const valuesInNameLabel = list.options.map(item => {
                            return {
                                name: item.value,
                                label: item.lable
                            }
                        })

                        operators = [{
                            name: '=',
                            label: '='
                        }, {
                            name: '!=',
                            label: '!='
                        }]
                        inputType = 'radio'
                        valueEditorType = 'radio'
                        values = valuesInNameLabel

                    } else if (list.type === 'drop_down') {

                        let valuesInNameLabel = list.options.map(item => {
                            return {
                                name: item.value,
                                label: item.lable
                            }
                        })
                        
                        if(list.is_conditional_options === true){
                            for(let i = 0; i<list.conditional_options.length; i++){
                                const conditionalValuesInNameLabel = list.conditional_options[i].options.map(item => {
                                    return {
                                        name: item.value,
                                        label: item.lable
                                    }
                                })
                                valuesInNameLabel = valuesInNameLabel.concat(conditionalValuesInNameLabel)
                            }
                        }

                        operators = [{
                            name: '=',
                            label: '='
                        }, {
                            name: '!=',
                            label: '!='
                        }]

                        if (defaultValue === '') {
                            defaultValue = valuesInNameLabel[0].name
                        }

                        valueEditorType = 'select'
                        values = valuesInNameLabel
                        inputType = 'select'
                    } else if (list.type === 'switch') {

                        operators = [{
                            name: '=',
                            label: '='
                        }]
                        inputType = 'select'
                        valueEditorType = 'select'
                        values = [{
                            name: 'Yes',
                            label: 'Yes'
                        }, {
                            name: 'No',
                            label: 'No'
                        }]
                        defaultValue = values[0].name
                    } else if (list.type === 'state') {
                        operators = [{
                            name: '=',
                            label: '='
                        }, {
                            name: '!=',
                            label: '!='
                        }]
                        valueEditorType = 'select'
                        values = this.rootStore.stateStore.statesWithNameLabel
                        inputType = 'select'
                        defaultValue = values[0].name
                    }

                    const data = {
                        name: list.variable_name,
                        label: list.name,
                        operators: operators,
                        valueEditorType: valueEditorType,
                        values: values,
                        inputType: inputType,
                        defaultValue: defaultValue,
                        placeholder: list.placeholder
                    }
                    return data
                })
            }
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the field details in fieldDetails variable for show in update field page
    setFieldDetails = (value: string): void => {
        this.isLoading = true
        getFieldDetails(value).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.fieldDetails = new SingleFieldStore(response.data.data)
        }).catch((error) => {

            if (error.request.status === 401) {

                this.rootStore.authStore.setAuthToken('')

            }

            this.isLoading = false

            this.isApiError = true

            this.apiErrorMessage = error.message

        })
    }

    //update field details
    updateFieldDetails = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            updateField(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchField(this.page, this.searchItem, 'update', this.searchByTemplateFilter, this.isGlobal, this.isConditional, this.isSubField, this.isHidden, this.searchByTypeFilter, this.searchByGroupField)
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }
}

export default FieldStore
