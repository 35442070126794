import { observable } from 'mobx'
import { Note, VisionInformation, SingleVision, AgreementInfo, EstatePlanProblemReviews, EstateProtectPlan, ClientRightsDiscloser, WealthAdvisoryAgreement, Recordings } from '../types.d'

class SingleVisionStore implements VisionInformation {
    @observable public _id: string
    @observable public modified_by: string
    @observable public added_by: string
    @observable public client_name: string
    @observable public createdAt: string
    @observable public updatedAt: string
    @observable public notes: Array<Note>
    @observable public discount_level: number
    @observable public agreement: AgreementInfo
    @observable public estate_plan_problem_reviews: Array<EstatePlanProblemReviews>
    @observable public estate_protect_plan: Array<EstateProtectPlan>
    @observable public wealth_advisory_agreement: WealthAdvisoryAgreement
    @observable public is_married: boolean
    @observable public estimated_assets_at_risk: string
    @observable public estimated_assets_at_risk_to_lawsuits: string
    @observable public client_rights_disclosure: ClientRightsDiscloser
    @observable public basic_plan_image: string
    @observable public income_graph_image: string
    @observable public agreement_first_signature: string
    @observable public agreement_second_signature: string
    @observable public agreement_third_signature: string
    @observable public crd_first_signature: string
    @observable public contract_first_signature: string
    @observable public contract_second_signature: string
    @observable public advisor_signature: string
    @observable public crd_second_signature: string
    @observable public assets: string
    @observable public is_client_has_trust: boolean
    @observable public lawmatics_ep_qualified: string
    @observable public ep_qualified_at: string
    @observable public lawmatics_matter_id: string
    @observable public lawmatics_contact_id: string
    @observable public zephyr_probable_uid: string
    @observable public recordings: Array<Recordings>

    constructor(vision: SingleVision) {
        this.set(vision)
    }

    set(vision: SingleVision): void {
        this._id = vision._id
        this.modified_by = vision.modified_by
        this.added_by = vision.added_by
        this.client_name = vision.client_name
        this.createdAt = vision.createdAt
        this.updatedAt = vision.updatedAt
        this.notes = vision.notes
        this.discount_level = vision.discount_level
        this.agreement = vision.agreement
        this.estate_plan_problem_reviews = vision.estate_plan_problem_reviews
        this.estate_protect_plan = vision.estate_protect_plan
        this.is_married = vision.is_married
        this.estimated_assets_at_risk = vision.estimated_assets_at_risk
        this.estimated_assets_at_risk_to_lawsuits = vision.estimated_assets_at_risk_to_lawsuits
        this.client_rights_disclosure = vision.client_rights_disclosure
        this.wealth_advisory_agreement = vision.wealth_advisory_agreement
        this.basic_plan_image = vision.basic_plan_image
        this.income_graph_image = vision.income_graph_image
        this.agreement_first_signature = vision.agreement_first_signature
        this.agreement_second_signature = vision.agreement_second_signature
        this.agreement_third_signature = vision.agreement_third_signature
        this.crd_first_signature = vision.crd_first_signature
        this.crd_second_signature = vision.crd_second_signature
        this.contract_first_signature = vision.contract_first_signature
        this.contract_second_signature = vision.contract_second_signature
        this.advisor_signature = vision.advisor_signature
        this.assets = vision.assets
        this.is_client_has_trust = vision.is_client_has_trust
        this.lawmatics_ep_qualified = vision.lawmatics_ep_qualified
        this.ep_qualified_at = vision.ep_qualified_at
        this.lawmatics_matter_id = vision.lawmatics_matter_id
        this.lawmatics_contact_id = vision.lawmatics_contact_id
        this.zephyr_probable_uid = vision.zephyr_probable_uid
        this.recordings = vision.recordings
    }

    get(): SingleVision {
        return {
            _id: this._id,
            modified_by: this.modified_by,
            added_by: this.added_by,
            client_name: this.client_name,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            notes: this.notes,
            discount_level: this.discount_level,
            agreement: this.agreement,
            estate_plan_problem_reviews: this.estate_plan_problem_reviews,
            estate_protect_plan: this.estate_protect_plan,
            wealth_advisory_agreement: this.wealth_advisory_agreement,
            is_married: this.is_married,
            estimated_assets_at_risk: this.estimated_assets_at_risk,
            estimated_assets_at_risk_to_lawsuits: this.estimated_assets_at_risk_to_lawsuits,
            client_rights_disclosure: this.client_rights_disclosure,
            basic_plan_image: this.basic_plan_image,
            income_graph_image: this.income_graph_image,
            agreement_first_signature: this.agreement_first_signature,
            agreement_second_signature: this.agreement_second_signature,
            agreement_third_signature: this.agreement_third_signature,
            crd_first_signature: this.crd_first_signature,
            crd_second_signature: this.crd_second_signature,
            contract_first_signature: this.contract_first_signature,
            contract_second_signature: this.contract_second_signature,
            advisor_signature: this.advisor_signature,
            assets: this.assets,
            is_client_has_trust: this.is_client_has_trust,
            lawmatics_ep_qualified: this.lawmatics_ep_qualified,
            ep_qualified_at: this.ep_qualified_at,
            lawmatics_matter_id: this.lawmatics_matter_id,
            lawmatics_contact_id: this.lawmatics_contact_id,
            zephyr_probable_uid: this.zephyr_probable_uid,
            recordings: this.recordings
        }
    }
}

export default SingleVisionStore