import { observable } from 'mobx'
import RootStore from './Root'
import { getStates, getStateDetails, updateOrDeleteState, getAllStates } from '../api/StateActions'
import { NameLabel, ValueLabel } from './types.d'
import { initialTableDataWithPaginationDetails } from './InitialValues'

interface StateInformation {
    _id?: string,
    state_name: string,
    state_code: string
}

interface StateData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<StateInformation>
}

interface Dictionary<T> {
    // eslint-disable-next-line
    [Key: string]: T | any
}

class StateStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public stateDetails: StateInformation
    @observable public stateData: StateData
    @observable public allStateNames: Array<StateInformation>
    @observable public statesWithValueLabel: Array<ValueLabel>
    @observable public statesWithNameLabel: Array<NameLabel>
    @observable public stateNamesAndCodesWithValueLabel: Array<ValueLabel>
    @observable public stateNamesAndCodesWithAllAsOption: Array<ValueLabel>
    @observable public searchItem: string
    @observable public page: number
    @observable public stateDataHistory: Array<StateData>

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.statesWithValueLabel = []
        this.statesWithNameLabel = []
        this.searchItem = ''
        this.page = 1
        this.stateNamesAndCodesWithValueLabel = []
        this.stateNamesAndCodesWithAllAsOption = []
        this.stateDetails = {
            _id: '',
            state_name: '',
            state_code: ''
        }
        this.stateDataHistory = []
        if (rootStore.authStore.isAdmin) {
            this.fetchState(1, '', '')
        }
        this.getAllStateNames()
        this.stateData = initialTableDataWithPaginationDetails
    }

    //get the list of states with details
    fetchState = (page: number, searchItem: string, action: string): void => {

        if (action === 'change_page') {
            this.page = page
            
            for (let i = 0; i < this.stateDataHistory.length; i++) {
                if (this.stateDataHistory[i].currentPage === page) {
                    this.stateDataHistory.push(this.stateData)
                    this.stateData = this.stateDataHistory[i]
                    return
                }
            }
            this.stateDataHistory.push(this.stateData)
        } else if (action === 'search_item') {
            this.stateDataHistory = []
            this.searchItem = searchItem
            this.page = 1
        } else if(action === 'update' || action === 'add') {
            this.stateDataHistory = []
        }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem }

        getStates(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.stateData = response.data.data
            this.stateDetails = {
                _id: '',
                state_name: '',
                state_code: ''
            }
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the state details in stateDetails variable for show in update state page
    setStateDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getStateDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.stateDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update or delete the state details of given state_id
    updateOrDeleteStateDetails = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            updateOrDeleteState(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchState(this.page, this.searchItem, 'update')
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    // all state name and id
    getAllStateNames = async (): Promise<void> => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        await getAllStates().then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.allStateNames = response.data.data
            this.statesWithValueLabel = this.allStateNames.map((list: any) => {
                const data = {
                    value: list._id,
                    label: list.state_name
                }
                return data
            })

            this.statesWithNameLabel = this.allStateNames.map((list: any) => {
                const data = {
                    name: list._id,
                    label: list.state_name
                }
                return data
            })

            this.stateNamesAndCodesWithValueLabel = this.allStateNames.map((list: any) => {
                const data = {
                    value: list._id,
                    label: list.state_name + ' (' + list.state_code + ')'
                }
                return data
            })

            this.stateNamesAndCodesWithAllAsOption = this.allStateNames.map((list: any) => {
                const data = {
                    value: list._id,
                    label: list.state_name + ' (' + list.state_code + ')'
                }
                return data
            })
            
            this.stateNamesAndCodesWithAllAsOption.unshift({
                value: 'all',
                label: 'All'
            })

        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default StateStore
