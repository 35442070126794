import { observable } from 'mobx'
import RootStore from './Root'
import { getConditions, addUpdateCondition, getConditionDetails, getConditionsNameId } from '../api/ConditionActions'
import { initialTableDataWithPaginationDetails } from './InitialValues'
import { Dictionary, ValueLabel } from './types.d'
import { RuleGroupType } from 'react-querybuilder'

interface ConditionInformation {
    _id?: string,
    name: string,
    conditions: RuleGroupType,
    description: string
}

interface ConditionData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<ConditionInformation>
}

class ConditionStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public conditionDetails: ConditionInformation
    @observable public conditionData: ConditionData
    @observable public searchItem: string
    @observable public page: number
    @observable public conditionDataHistory: Array<ConditionData>
    @observable public conditionNameId: Array<ValueLabel>
    @observable public conditionNameIdWithNoneAsOption: Array<ValueLabel>

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.page = 1
        this.searchItem = ''
        this.conditionDetails = {
            _id: '',
            name: '',
            conditions: {
                id: '',
                combinator: 'and',
                rules: []
            },
            description: ''
        }
        this.conditionDataHistory = []
        if(rootStore.authStore.isAdmin){
            this.resetConditionDetails()
            this.fetchCondition(1, '', '')
        }
        this.conditionData = initialTableDataWithPaginationDetails
        this.conditionNameId = []
        this.conditionNameIdWithNoneAsOption = []
        this.fetchConditionsNameId()
    }

    resetConditionDetails = (): void => {
        this.conditionDetails = {
            _id: '',
            name: '',
            conditions: {
                id: '',
                combinator: 'and',
                rules: []
            },
            description: ''
        }
    }

    //get the list of conditions with details
    fetchCondition = (page: number, searchItem: string, action: string): void => {

        if (action === 'change_page') {
            this.page = page

            for (let i = 0; i < this.conditionDataHistory.length; i++) {
                if (this.conditionDataHistory[i].currentPage === page) {
                    this.conditionDataHistory.push(this.conditionData)
                    this.conditionData = this.conditionDataHistory[i]
                    return
                }
            }
            this.conditionDataHistory.push(this.conditionData)
        } else if (action === 'search_item') {
            this.conditionDataHistory = []
            this.searchItem = searchItem
            this.page = 1
        } else if (action === 'update' || action === 'add') {
            this.conditionDataHistory = []
        }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem }

        getConditions(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.conditionData = response.data.data
            this.resetConditionDetails()
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the condition details in conditionDetails variable for show in update condition page
    setConditionDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getConditionDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.conditionDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //add or update the condition details of given condition_id
    addUpdateSingleCondition = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            addUpdateCondition(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchCondition(this.page, this.searchItem, 'update')
                this.fetchConditionsNameId()
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    fetchConditionsNameId = (): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getConditionsNameId().then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.conditionNameId = (response.data.data.length !== 0) ? response.data.data.map((item: any) => {
                return {
                    label: item.name,
                    value: item._id
                }
            }) : []

            this.conditionNameIdWithNoneAsOption = (response.data.data.length !== 0) ? response.data.data.map((item: any) => {
                return {
                    label: item.name,
                    value: item._id
                }
            }) : []
            this.conditionNameIdWithNoneAsOption.unshift({
                value: 'none',
                label: 'None'
            })

        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default ConditionStore
