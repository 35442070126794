import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import classNames from 'classnames'


interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const UpdatePackagePage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { templateStore, packageStore } = rootStore
    const { allTemplatesName,  } = templateStore
    const { isLoading, isApiError, apiErrorMessage, packageDetails, setPackageDetails, updatePackageDetails, resetPackageDetails } = packageStore

    const [isCheckAllTemplate, setIsCheckAllTemplate] = useState(false)
    const [checkedTemplates, setCheckedTemplates] = useState<Array<string>>([])

    const history = useHistory()
    const pathArrayWithPackageId = history.location.pathname.split('/')
    const packageId = pathArrayWithPackageId[3]

    useEffect(() => {
        if (packageDetails._id === '' || packageId !== packageDetails._id) {
            setPackageDetails(packageId)
        }

        if(packageDetails._id !== '' ) {
            setCheckedTemplates(packageDetails.templates || [])
        }
    
    }, [packageId, setPackageDetails, setCheckedTemplates, packageDetails])

    const handleClick = () => {
        setCheckedTemplates([])
        resetPackageDetails()
        history.push('/packages/')
    }

    const handleOnClickSelectAll = () => {
        setIsCheckAllTemplate(!isCheckAllTemplate)
        setCheckedTemplates(allTemplatesName.map(list => list._id))
        if (isCheckAllTemplate) {
            setCheckedTemplates([])
        }
    }

    const handleOnClickTemplate = (e: any) => {
        const { value, checked } = e.target
        setIsCheckAllTemplate(false)
        setCheckedTemplates([...checkedTemplates, value])
        if (!checked) {
            setCheckedTemplates(checkedTemplates.filter(item => item !== value))
        }
    }


    const onSubmit = async (data: Dictionary<string>) => {

        const requestData = {
            package_id: packageId,
            name: data.name,
            templates: checkedTemplates,
            display_order: data.display_order
        }

        const response = await updatePackageDetails(requestData)
        if (response === 'true') {
            history.push('/packages/')
        }
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Packages', to: '/packages/' },
            { name: 'Update', isActive: true }
        ]} />
        <div className="card">
            <div className="card-header">
        Update Package
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(packageId === packageDetails._id)
                            ? <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>
                                    <InputField
                                        label='Name *'
                                        fieldName='name'
                                        register={register}
                                        errors={errors.name}
                                        placeholder='Enter Name'
                                        defaultValue={packageDetails.name}
                                        required={true}
                                    />
                                    <InputField
                                        label='Slug *'
                                        fieldName='slug'
                                        register={register}
                                        errors={errors.slug}
                                        defaultValue={packageDetails.slug}
                                        required={true}
                                        disabled={true}
                                    />
                                    <div className="form-group">
                                        <label>Display Order</label>
                                        <input
                                            ref={register({
                                                required: 'This field is required'
                                            })}
                                            name='display_order'
                                            type='number'
                                            min={1}
                                            className={classNames('form-control', { 'is-invalid': errors.display_order })}
                                            defaultValue={packageDetails.display_order}
                                        />
                                        {errors.display_order && (<div className='invalid-feedback'>{errors.display_order.message}</div>)}
                                    </div>
                                    <div className='form-group'>
                                        <label>Templates</label>
                                        <div className='checkbox' >
                                            <label><input name='templates' type='checkbox' ref={register} value='' checked={allTemplatesName.length === checkedTemplates.length} onChange={handleOnClickSelectAll} /> Select / Deselect All </label><br/>
                                            {allTemplatesName.map((list) =>
                                                <> <label><input name='templates' type='checkbox' ref={register} key={list._id} checked={checkedTemplates.includes(list._id)} value={list._id} onChange={handleOnClickTemplate} /> {list.name} </label><br/></>
                                            )}
                                        </div>
                                        {errors.templates && (<div className='invalid-feedback'>{errors.templates.message}</div>)}
                                    </div>
                                    <div className='form-group'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(UpdatePackagePage)