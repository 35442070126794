import React, { useEffect, useState } from 'react'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import { Props } from '../../store/types.d'
import { Link, useParams } from 'react-router-dom'
import { dateFormat, FormateDate, ShowDetails } from '../commonComponent/commonFuction'
import { Cell } from '../table/TableCellText.d'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import DatePicker from 'react-datepicker'
import { addUpdateMatterDocumentDate, setCMSCountyInMatter, sendMailOfNFToClient } from '../../api/MatterAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import uuid from 'react-uuid'
import 'react-datepicker/dist/react-datepicker.css'
import Modal from '../commonComponent/Modal'
import { initialTemplate } from '../../store/InitialValues'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import moment from 'moment'
import TableCellTo from '../emailLogs/TableCellTo'
import TableCellYesNo from '../emailLogs/TableCellYesNo'
import { TableCellErrorDetails } from '../emailLogs/TableCellErrorData'
import { TableCellEmailDetails } from '../emailLogs/TableCellEmailDetail'
import TableCellAutoOrManual from './TableCellAutoOrManual'

const TableCellNameWithEditIndicator: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    return (
        <>
            {(data.document.is_document_edited) ? <>{value}<span className='float-right text-secondary' title='Edited Template'><FontAwesomeIcon icon={faEdit} /></span></> : value}
        </>
    )
}

const TableCellPreviewNew: React.FC<Cell> = (props) => {

    let value = props.value || ''
    const isAdmin = props.data.isAdmin
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    const { generateDocx, generateNewPDF, document, templateDetails, generateTableOfContentPDF, generatePDFWithPageReset } = props.data

    const previewRowCell: Array<JSX.Element> = []

    // const cellData = clientDocuments.find((doc) => doc.document_id === value)
    const cellData = document
    if (cellData !== undefined) {
        for (let i = 0; i < cellData.links.length; i++) {
            const colCell: Array<JSX.Element> = []
            for (let j = 0; j < cellData.links[i].length; j++) {
                const linkData = cellData.links[i][j]
                const uuidForId = uuid()
                const tooltip = _.get(linkData, 'tooltip', {})
                colCell.push(
                    <React.Fragment>
                        {(linkData.pdfOrDoc !== 'tableOfContents' || (linkData.pdfOrDoc === 'tableOfContents' && isAdmin)) && <Link id={uuidForId} key={uuid()} className={linkData.label.includes('Delete') ? 'mr-2 text-danger float-right' : 'mr-2'} to={linkData.link} onClick={async () => {
                            if (linkData.arguments !== undefined) {
                                if (linkData.pdfOrDoc === 'doc') {
                                    await generateDocx(linkData.arguments.matterId, value, linkData.arguments.forSpouse, linkData.arguments.UUID)
                                } else if (linkData.pdfOrDoc === 'pdf') {
                                    await generateNewPDF(linkData.arguments.matterId, value, linkData.arguments.forSpouse, linkData.arguments.UUID)
                                } else if (linkData.pdfOrDoc === 'pdfPageReset') {
                                    await generatePDFWithPageReset(linkData.arguments.matterId, value, linkData.arguments.forSpouse, linkData.arguments.UUID)
                                } else if (linkData.pdfOrDoc === 'tableOfContents') {
                                    await generateTableOfContentPDF(linkData.arguments.matterId, value, linkData.arguments.forSpouse, linkData.arguments.UUID)
                                }
                            } else if (linkData.label.includes('Delete')) {
                                const EditIdPosition = cellData.links[i].length > 4 ? 2 : 1
                                const linkParts = cellData.links[i][j - EditIdPosition].link.split('/')
                                const htmlId = _.last(linkParts)
                                const sure = confirm(`Are you sure you want to delete the edited HTML of ${document.name} ?`)
                                if (sure) {
                                    templateDetails.deleteHTML(htmlId, (flag: string) => {
                                        if (flag !== 'false') {
                                            toast.success(flag)
                                            window.location.reload()
                                        } else {
                                            toast.error('Sorry document not deleted please try again !')
                                        }
                                    })
                                }
                            }
                        }}>{linkData.pdfOrDoc === 'pdfDeprecated' ? <FontAwesomeIcon icon={faFilePdf} /> : linkData.label}</Link>}
                        {!_.isEmpty(tooltip) && linkData.pdfOrDoc !== 'pdfDeprecated' ? <ReactTooltip anchorId={uuidForId} >
                            <div>
                                Created: {moment(tooltip.createdAt).format('MM/DD/YYYY h:mm a')}
                            </div>
                            <div>
                                Updated: {moment(tooltip.updatedAt).format('MM/DD/YYYY h:mm a')}
                            </div>
                        </ReactTooltip> : !_.isEmpty(tooltip) && linkData.pdfOrDoc === 'pdfDeprecated' && <ReactTooltip anchorId={uuidForId} >{tooltip}</ReactTooltip>
                        }
                    </React.Fragment>
                )
            }
            previewRowCell.push(
                <div key={uuid()}>
                    {colCell}
                </div>
            )
        }
    }
    return (<div key={uuid()}>{previewRowCell}</div>)
}

interface MattersDocumentPageParams {
    clientId: string
    matterId: string
}

const MattersDocumentPage: React.FC<Props> = ({ rootStore }) => {
    const { clientStore, templateStore, setTitle, authStore } = rootStore
    const { isAdmin } = authStore
    const {
        isApiError,
        apiErrorMessage,
        isLoading,
        matter,
        setCurrentMatter,
        generateDocx,
        allDocumentsInZipExport,
        generateNewPDF,
        previewDocument,
        documentsHTMLInZip,
        generateTableOfContentPDF,
        generatePDFWithPageReset,
        fetchEmailLogsOfMatter,
        emailLogs,
        searchKeyword,
        setSearchKeyword,
        filteredDocumentsData
    } = clientStore
    const { templateDetails } = templateStore

    const { clientId, matterId } = useParams<MattersDocumentPageParams>()

    const [startDate, setStartDate] = useState<Date>()
    const [action, setAction] = useState<string>('all')
    const [CMSCounty, setCMSCounty] = useState<string>()

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showEmailLogs, setShowEmailLogs] = useState(false)

    useEffect(() => {
        if (matter._id === '' || matter._id !== matterId) {
            setCurrentMatter(matterId)
            setTitle(matter.name)
        }
        templateDetails.set(initialTemplate)
        previewDocument.set(initialTemplate)
        if (matter._id !== '' && matter._id === matterId) {
            if (matter.generate_date !== '' && startDate === undefined) {
                const showDate = matter.generate_date.replace(/-/g, '/').split('T')
                setStartDate(new Date(showDate[0]))
            }
            if (matter.cms_county !== undefined && matter.cms_county !== '' && CMSCounty === undefined) {
                setCMSCounty(matter.cms_county)
            }
        }
    }, [matter, matterId, previewDocument, setCurrentMatter, startDate, templateDetails, setTitle, setCMSCounty, CMSCounty])

    const tableData = filteredDocumentsData
    const handleOnChangeOfDatePicker = (date: any) => {
        const requestData = {
            matter_id: matterId,
            generate_date: date.toString()
        }
        addUpdateMatterDocumentDate(requestData).then((response) => {
            if (response.data.success === 1) {
                setStartDate(date)
                setCurrentMatter(matterId)
                toast.success(response.data.message)
            }
        }).catch((e) => {
            console.log(e)
            toast.error(e.message)
        })
    }

    const handleOnApplyCounty = () => {
        const requestData = {
            _id: matterId,
            cms_county: CMSCounty
        }
        if (CMSCounty !== undefined) {
            setCMSCountyInMatter(requestData).then((response) => {
                if (response.data.success === 1) {
                    setCurrentMatter(matterId)
                    toast.success(response.data.message)
                }
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    const handleOnClickOfSendMsg = () => {
        const requestData = {
            matter_id: matterId
        }
        const sure = confirm('Are you sure you want to send mail of Name And Fiduciary & other selected documents to client ?')
        if (sure) {
            sendMailOfNFToClient(requestData).then((response) => {
                if (response.data.success === 1) {
                    setCurrentMatter(matterId)
                    toast.success(response.data.message)
                }
            }).catch((e) => {
                toast.success(e.message)
            })
        }
    }

    const compareDate: any = async (date: Date | undefined, generateDate: string, action: string) => {

        const generateDateArray = generateDate.replace(/-/g, '/').split('T')
        const generateDateInIstFormate = new Date(generateDateArray[0])
        if (date?.getTime() == generateDateInIstFormate.getTime()) {
            setShowConfirmModal(true)
            setAction(action)
        } else if (date?.getTime() != generateDateInIstFormate.getTime()) {
            await handleOnChangeOfDatePicker(date)
            setShowConfirmModal(true)
            setAction(action)
        }
    }

    const tableDataOfDeed = matter.deed_links !== undefined ? matter.deed_links?.map((item) => {
        return {
            _id: item.deed._id,
            document: { ...item.deed, links: item.links },
            generateDocx,
            generateNewPDF,
            templateDetails,
            setCurrentMatter,
            generateTableOfContentPDF,
            generatePDFWithPageReset
        }
    }) : []

    const handleDocSearch = (e: any) => {
        setSearchKeyword(e.target.value)
    }

    const openCloseConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal)
    }

    const openCloseEmailLogs = () => {
        setShowEmailLogs(!showEmailLogs)
    }

    const onSaveModal = async () => {
        openCloseConfirmModal()
        await allDocumentsInZipExport(matterId, action)
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div>
            <Breadcrumb links={[
                { name: 'Clients', to: '/clients/' },
                { name: 'Matters', to: `/clients/${clientId}` },
                { name: 'Documents', isActive: true }
            ]} />

            <div className="card">
                <div className="card-header">
                    Documents of Matter
                    <div className='float-right'>
                        <Link to={`/clients/${clientId}`} className='btn btn-sm btn-outline-primary'>Back</Link>
                    </div>
                </div>

                <div className="card-body">
                    <div className='row'>
                        <div className='col'>

                            {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                            <div>
                                <b>Client Details:</b>
                                <hr />
                                <ShowDetails key={uuid()} title='First Name' value={matter.client.first_name} />
                                <ShowDetails key={uuid()} title='Middle Name' value={matter.client.middle_name} />
                                <ShowDetails key={uuid()} title='Last Name' value={matter.client.last_name} />
                                <ShowDetails key={uuid()} title='Gender' value={matter.client.gender} />
                                <ShowDetails key={uuid()} title='Street' value={matter.client.street} />
                                <ShowDetails key={uuid()} title='City' value={matter.client.city} />
                                <ShowDetails key={uuid()} title='State' value={matter.client.state.name} />
                                <ShowDetails key={uuid()} title='Country' value={matter.client.country} />
                                <ShowDetails key={uuid()} title='County' value={matter.client.county} />
                                <ShowDetails key={uuid()} title='County Reference' value={matter.client.county_ref} />
                                <ShowDetails key={uuid()} title='Mobile Number' value={matter.client.phone_numbers?.mobile || ''} />
                                <ShowDetails key={uuid()} title='Home Contact Number' value={matter.client.phone_numbers?.home || ''} />
                                <ShowDetails key={uuid()} title='Work Contact Number' value={matter.client.phone_numbers?.work || ''} />
                                <ShowDetails key={uuid()} title='Email Address' value={matter.client.email || ''} />
                                <hr />
                                <b>Matter Details:</b>
                                <hr />
                                <ShowDetails key={uuid()} title='Name' value={matter.name} />
                                <ShowDetails key={uuid()} title='Type' value={matter.type} />
                                <ShowDetails key={uuid()} title='Added By' value={matter.added_by} />
                                <ShowDetails key={uuid()} title='Modifier Name' value={matter.modifier_name} />
                                <ShowDetails key={uuid()} title='State' value={matter.state_name} />
                                <ShowDetails key={uuid()} title='County' value={matter.county} />
                                <ShowDetails key={uuid()} title='Package' value={matter.package} />
                                <ShowDetails key={uuid()} title='Note' value={matter.note} />
                                <ShowDetails key={uuid()} title='Updated At' value={matter.updatedAt} />
                                <ShowDetails key={uuid()} title='Created At' value={matter.createdAt} />
                                <ShowDetails key={uuid()} title='Signing Date (iPad)' value={matter.signing_date} />
                                {localStorage.getItem('userRole') == ('SUPER_ADMIN' || 'ADMIN_CMS_TEAM_IPAD') ?
                                    <>
                                        <hr />
                                        <b>Lawmatics Information: </b>
                                        <hr />

                                        <ShowDetails key={uuid()} title='Matter Id' value={matter.lawmatics_matter_id} />
                                        <ShowDetails key={uuid()} title='Contact Id' value={matter.lawmatics_contact_id} />
                                        <ShowDetails key={uuid()} title='Probable Uid' value={matter.zephyr_probable_uid} />
                                    </> : null}
                                <hr />
                                <b>Automated Email to Client of N&F Doc:</b>
                                <hr />
                                <ShowDetails key={uuid()} title='Has an Automated Email Been Sent?' value={(matter.is_name_and_fiduciary_mail_sent === true) ? 'Yes' : 'No'} />
                                <ShowDetails key={uuid()} title='Automated Email Dispatched At' value={matter.automatic_mail_sent_at} helpText='Note: An automated email will be sent after 30 mins once the singing date added form iPad app, if the toggle is on.' />
                                <hr />
                                <div className='row mt-4'>
                                    <div className="col col-md-7">
                                        {showConfirmModal ? (
                                            <Modal show={showConfirmModal} modalTitle='Confirm Date' openCloseModal=
                                                {openCloseConfirmModal} onSaveModal={onSaveModal} saveButtonName='Confirmed' modalDialogClass='modal-dialog-centered' >
                                                <div className='text-wrap'>
                                                    Are you sure you want to Download Documents for the date {dateFormat(matter.generate_date)} ?
                                                </div>
                                            </Modal>
                                        ) : null}
                                        <div>
                                            {matter.is_locked == true && <Link to='#' className="btn btn-warning mr-2 link-download" onClick={async () => {
                                                const sure = confirm('Are you sure you want to download edited documents of this matter ?')
                                                if (sure) {
                                                    documentsHTMLInZip(matter._id)
                                                }
                                            }}>
                                                <FontAwesomeIcon icon={faDownload} /> Download Highlights
                                            </Link>}

                                            <Link to='#' className="btn btn-primary mr-2" role="button" id="dropdownMenuLink1" onClick={async () => {
                                                compareDate(startDate, matter.generate_date, 'zipInPDF')
                                            }}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Link>

                                            <Link to='#' className='btn btn-primary mr-2 link-download' onClick={async () => {
                                                handleOnClickOfSendMsg()
                                            }}>
                                                Send N&F and Healthcare Documents to the client
                                            </Link>
                                            <br/>
                                            <Link to='#' className='btn btn-primary link-download mt-2' onClick={async () => {
                                                if(showEmailLogs == false) {
                                                    fetchEmailLogsOfMatter(matterId)
                                                }
                                                openCloseEmailLogs()
                                            }}>
                                                { showEmailLogs == true ? 'Hide' : 'Show'} Email Logs
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='col col-md-5 d-flex justify-content-end'>
                                        <table className=''>
                                            <tr>
                                                <td>
                                                    <small className='font-small'>Signing County:</small>
                                                </td>
                                                <td>
                                                    <input type="text" className='mr-2' value={CMSCounty} onChange={(e) => setCMSCounty(e.target.value)} />
                                                    <button className='btn btn-sm btn-outline-primary px-20' disabled={CMSCounty === undefined} onClick={() => handleOnApplyCounty()}>Apply</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <small className='font-small'>Signing Date:</small>
                                                </td>
                                                <td>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleOnChangeOfDatePicker} >
                                                        <div className='text-muted pl-1'>Selected date will reflect in the documents</div>
                                                    </DatePicker>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {showEmailLogs && <div>
                                <br />
                                <hr/>
                                <b>Email Logs</b>
                                <div className='table-responsive mt-3'>
                                    <Table
                                        isLoading={isLoading}
                                        unique_key='_id'
                                        columns={[
                                            { name: 'user_id[0].name', title: 'Client Name' },
                                            { name: 'type', title: 'Sent', component: TableCellAutoOrManual },
                                            { name: 'to', title: 'To', component: TableCellTo },
                                            { name: 'sent_status', title: 'Is Sent', component: TableCellYesNo },
                                            { name: 'createdAt', title: 'Created', component: FormateDate },
                                            { name: '_id', title: 'Details', component: TableCellEmailDetails },
                                            { name: 'error', title: 'Request Data', component: TableCellErrorDetails },
                                        ]}
                                        data={emailLogs}
                                    ></Table>
                                </div>
                            </div>}
                            <hr/>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <b>Documents:</b>
                                    <div style={{ fontSize: '12px' }}><b>Note:</b> The icon <span className='text-secondary'><FontAwesomeIcon icon={faEdit} /></span> indicates the document is updated for this client.</div>
                                </div>
                                <div>
                                    <input className="form-control" name="search" type="text" placeholder="Search Documents" aria-label="Search" onChange={handleDocSearch} value={searchKeyword} />
                                </div>
                            </div>

                            <div className='table-responsive mt-3'>
                                <Table
                                    isLoading={isLoading}
                                    unique_key={uuid()}
                                    columns={[
                                        { name: 'document.name', title: 'Document Name', component: TableCellNameWithEditIndicator },
                                        { name: 'document._id', title: 'View', component: observer(TableCellPreviewNew) }
                                    ]}
                                    data={tableData.map((item) => {
                                        return { ...item, isAdmin }
                                    })}
                                ></Table>
                            </div>

                            {!_.isEmpty(tableDataOfDeed) && <div>
                                <b>Deeds</b>
                                <div className='table-responsive mt-3'>
                                    <Table
                                        isLoading={isLoading}
                                        unique_key={uuid()}
                                        columns={[
                                            { name: 'document.name', title: 'Document Name', component: TableCellNameWithEditIndicator },
                                            { name: 'document._id', title: 'View', component: observer(TableCellPreviewNew) }
                                        ]}
                                        data={tableDataOfDeed}
                                    ></Table>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(MattersDocumentPage)