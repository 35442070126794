import React, { useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { Cell } from '../table/TableCellText.d'
import { FormateDate } from '../commonComponent/commonFuction'

interface Props {
  rootStore: RootStore,
}

const ShowDetails: React.FC<Cell> = (props) => {
    const value = props.value || ''
    const matterId = props.data.matter_id

    return (
        <>{value ? <div><strong>Message: </strong>  {props.value}<br/><strong>Matter Id: </strong>{matterId}</div> : '' }</>
    )
}
const LawmaticsCronDetailPage: React.FC<Props> = ({ rootStore }) => {
    const { lawmaticsStore } = rootStore

    const { searchItem, isApiError, isLoading, apiErrorMessage, lawmaticsCronData, fetchLawmaticsCronData } = lawmaticsStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchLawmaticsCronData(page, searchItem)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        fetchLawmaticsCronData(1, e.target.value)
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Lawmatics Cron Details
                </div>
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'appointments_found', title: 'Appointments' },
                                    { name: 'matters_found', title: 'Matters' },
                                    { name: 'new_matters_found', title: 'New Matters' },
                                    { name: 'new_probable_found', title: 'New Probable' },
                                    { name: 'createdAt', title: 'Created At', component: FormateDate },
                                    { name: 'error_message', title: 'Errors', component: ShowDetails }
                                ]}
                                data={lawmaticsCronData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={lawmaticsCronData.currentPage}
                            totalPages={lawmaticsCronData.totalPage}
                            totalItems={lawmaticsCronData.totalItem}
                            itemsPerPage={lawmaticsCronData.itemPerPage}
                            isFiltered={lawmaticsCronData.isFiltered}
                            totalAllItems={lawmaticsCronData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(LawmaticsCronDetailPage)
