import { observable } from 'mobx'
import RootStore from '../Root'
import { InvoiceProps } from '../types.d'
import { getInvoiceInformation } from '../../api/vision/InvoiceActions'
import { initialTableDataWithPaginationDetails } from '../InitialValues'

interface InvoiceInfoData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<InvoiceProps>
}

class InvoiceStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public invoiceInformationData: InvoiceInfoData
    @observable public searchItem: string
    @observable public page: number

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.searchItem = ''
        this.page = 1
        if(rootStore.authStore.isAdmin){
            this.fetchInvoice(1, '')
        }
        this.invoiceInformationData = initialTableDataWithPaginationDetails
    }

    //get the list of trustee info with details
    fetchInvoice = (page: number, searchItem: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = { page: page, search_item: searchItem }
        this.page = page
        this.searchItem = searchItem

        getInvoiceInformation(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.invoiceInformationData = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

}

export default InvoiceStore
