import { observable } from 'mobx'
import RootStore from './Root'
import { EmailLogCronInformation } from './types.d'
import { initialTableDataWithPaginationDetails, initialEmailLogCronData } from './InitialValues'
import { getEmailLogs } from '../api/EmailLogAction'

interface EmailLogData {
    isFiltered: boolean | undefined
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    totalAllItems: number,
    page_items: Array<EmailLogCronInformation>
}

class EmailLogStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public emailLogCronDetails: EmailLogCronInformation
    @observable public emailLog: EmailLogData
    @observable public searchItem: string
    @observable public page: number
    @observable public isLoadingInfo: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.searchItem = ''
        this.page = 1
        this.emailLogCronDetails = initialEmailLogCronData
        this.isLoadingInfo = false
        if (rootStore.authStore.isAdmin) {
            this.fetchEmailLogCronData(1, '')
        }
        this.emailLog = initialTableDataWithPaginationDetails
    }


    //get the list of plurals with details
    fetchEmailLogCronData = (page: number, searchItem: string): void => {
        
        this.searchItem = searchItem
        this.page = page
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: searchItem }

        getEmailLogs(requestData).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.emailLog = response.data.data
            this.emailLogCronDetails = initialEmailLogCronData
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

}

export default EmailLogStore
