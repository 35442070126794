import React, { useState } from 'react'
import { Cell } from '../table/TableCellText.d' 
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { isValueEquivalent } from '../commonComponent/commonFuction'
import Modal from '../commonComponent/Modal'
import uuid from 'react-uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser'

const extractFileName = (url: string): string => {
    const urlObj = new URL(url)
    const pathname = urlObj.pathname
    const segments = pathname.split('/')
    const fileName = segments[segments.length - 1]
    return fileName
}

export const TableCellEmailDetails: React.FC<Cell> = (props) => {
    const value = props.value || undefined
    const data = props.data || {}
    const [showModal, setShow] = useState(false)
    const [selectedRow, setSelectedRow] = useState({ _id: '', action: '' })
    

    const openCloseModal = () => {
        setShow(!showModal)
    }

    const onTableAction = (action: string, value: any, data: any) => {
        setSelectedRow({ _id: data._id, action: action })
        openCloseModal()
    }

    return (
        <>
            {data.sent_status === true && <Link to='#' data-target='#myModal' className='mr-2' onClick={() => {
                if (onTableAction != null) {
                    onTableAction('viewRequestData', value, data)
                }
            }}>Details</Link>}
            {showModal && isValueEquivalent({ _id: data._id, action: 'viewRequestData' }, selectedRow) ? (
                <Modal show={showModal}
                    modalTitle={data.subject}
                    openCloseModal={openCloseModal}
                    modalDialogClass='modal-lg'
                    cancelButtonName='Close'
                    isScrollable={true}
                    cancelButton={true}
                    isSaveButton={false}
                >
                    <>
                        <table width={'100%'} className='table table-borderless'>
                            <tr>
                                <th key={uuid()} >
                                    Type
                                </th>
                                <td key={uuid()} width={'85%'}>
                                : {_.startCase(_.toLower(data.type))}
                                </td>
                            </tr>
                            <tr>
                                <th key={uuid()}>
                                    To
                                </th>
                                <td key={uuid()}>
                                : {data.to}
                                </td>
                            </tr>
                            {props.data.bcc !== undefined && props.data.bcc.length > 0 && <tr>
                                <th key={uuid()}>
                                    Bcc
                                </th>
                                <td key={uuid()}>
                                : {data.bcc}
                                </td>
                            </tr>}
                            {props.data.cc !== undefined && props.data.cc.length > 0 && <tr>
                                <th key={uuid()}>
                                    Cc
                                </th>
                                <td key={uuid()}>
                                : {data.cc}
                                </td>
                            </tr>}
                            <tr>
                                <th key={uuid()}>
                                    From
                                </th>
                                <td key={uuid()}>
                                : {data.from}
                                </td>
                            </tr>
                        </table>
                        <hr />
                        <div className='no-wrap'>
                            {parse(data.body)}
                        </div>
                        <div>
                            {data.files.length !== 0 && data.files.map((file) => {
                                return <>
                                    <a key={uuid()} href={file} target='#_blank' >
                                        <FontAwesomeIcon icon={faFilePdf} className='mr-2' />
                                        {extractFileName(file)}
                                    </a>
                                    <br />
                                </>
                            })}
                        </div>
                    </>
                </Modal>) : null
            }
        </>
    )
}