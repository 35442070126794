import AuthStore from './Auth'
import axios from 'axios'
import NavStore from './Nav'
import AppUserStore from './AppUser'
import SuperAdminStore from './SuperAdmin'
import StateStore from './State'
import FieldStore from './Field'
import TemplateStore from './Template'
import PackageStore from './Package'
import ClientStore from './Client'
import TemplateFieldStore from './TemplateFIeld'
import PluralStore from './Plural'
import ConditionStore from './Condition'
import CommonParagraph from './CommonParagraph'
import MonacoEditorStore from './MonacoEditorStore'
import DeedResearchStore from './DeedResearch'
import MatterStore from './Matter'
import NeedInfoStore from './NeedInfo'
import SettingStore from './Settings'
import DayliteStore from './Daylite'
import DebugStore from './DebugStore'
import TrusteeInfoStore from './TrusteeInformation'
import PlanStore from './vision/Plan'
import ProblemQuestionStore from './vision/ProblemQuestion'
import EstateProtectPlanStore from './vision/EstateProtectPlan'
import VisionStore from './vision/Vision'
import ProbableStore from './Probable'
import InvoiceStore from './vision/Invoice'
import DeedFilingStore from './DeedFiling'
import LawmaticsStore from './Lawmatics'
import PlanPricingTemplateStore from './vision/PlanPricingTemplate'
import EngagementTemplateStore from './vision/EngagementTemplate'
import ErrorLogStore from './ErrorLog'
import ThirdPartyLogStore from './ThirdPartLog'
import TagStore from './Tag'
import EmailLogStore from './EmailLog'
import StateSpecificHCTempStore from './StateSpecificHCInfo'
import AudioStore from './vision/Audio'

axios.defaults.baseURL = process.env.REACT_APP_BASEURL

class RootStore {
    public authStore: AuthStore
    public navStore: NavStore
    public appUserStore: AppUserStore
    public superAdminStore: SuperAdminStore
    public stateStore: StateStore
    public fieldStore: FieldStore
    public templateStore: TemplateStore
    public packageStore: PackageStore
    public clientStore: ClientStore
    public templateFieldStore: TemplateFieldStore
    public pluralStore: PluralStore
    public conditionStore: ConditionStore
    public commonParagraph: CommonParagraph
    public monacoEditorStore: MonacoEditorStore
    public deedResearchStore: DeedResearchStore
    public deedFilingStore: DeedFilingStore
    public matterStore: MatterStore
    public needInfoStore: NeedInfoStore
    public settingStore: SettingStore
    public dayliteStore: DayliteStore
    public errorLogStore: ErrorLogStore
    public emailLogStore: EmailLogStore
    public lawmaticsStore: LawmaticsStore
    public debugStore: DebugStore
    public trusteeInfoStore: TrusteeInfoStore
    public invoiceInfoStore: InvoiceStore
    public planStore: PlanStore
    public problemQuestionStore: ProblemQuestionStore
    public estateProtectPlanStore: EstateProtectPlanStore
    public visionStore: VisionStore
    public planPricingTemplateStore: PlanPricingTemplateStore
    public engagementTemplateStore: EngagementTemplateStore
    public thirdPartyLogStore: ThirdPartyLogStore
    public tagStore: TagStore
    public probableStore: ProbableStore
    public stateSpecificHCTempStore: StateSpecificHCTempStore
    public audioStore: AudioStore
    public isAppLoading = true

    constructor() {
        this.authStore = new AuthStore(this)
        this.navStore = new NavStore(this)
        this.appUserStore = new AppUserStore(this)
        this.superAdminStore = new SuperAdminStore(this)
        this.stateStore = new StateStore(this)
        this.fieldStore = new FieldStore(this)
        this.templateStore = new TemplateStore(this)
        this.packageStore = new PackageStore(this)
        this.clientStore = new ClientStore(this)
        this.templateFieldStore = new TemplateFieldStore(this)
        this.pluralStore = new PluralStore(this)
        this.conditionStore = new ConditionStore(this)
        this.commonParagraph = new CommonParagraph(this)
        this.monacoEditorStore = new MonacoEditorStore(this)
        this.deedResearchStore = new DeedResearchStore(this)
        this.matterStore = new MatterStore(this)
        this.needInfoStore = new NeedInfoStore(this)
        this.settingStore = new SettingStore(this)
        this.dayliteStore = new DayliteStore(this)
        this.errorLogStore = new ErrorLogStore(this)
        this.emailLogStore = new EmailLogStore(this)
        this.lawmaticsStore = new LawmaticsStore(this)
        this.debugStore = new DebugStore(this)
        this.trusteeInfoStore = new TrusteeInfoStore(this)
        this.invoiceInfoStore = new InvoiceStore(this)
        this.planStore = new PlanStore(this)
        this.problemQuestionStore = new ProblemQuestionStore(this)
        this.estateProtectPlanStore = new EstateProtectPlanStore(this)
        this.visionStore = new VisionStore(this)
        this.probableStore = new ProbableStore(this)
        this.deedFilingStore = new DeedFilingStore(this)
        this.planPricingTemplateStore = new PlanPricingTemplateStore(this)
        this.engagementTemplateStore = new EngagementTemplateStore(this)
        this.thirdPartyLogStore = new ThirdPartyLogStore(this)
        this.tagStore = new TagStore(this)
        this.stateSpecificHCTempStore = new StateSpecificHCTempStore(this)
        this.audioStore = new AudioStore(this)
        this.isAppLoading = false
    }

    /**
     * set Dom document title
     * @param title string
     */
    setTitle(title: string) {
        document.title = title
    }
}

export default RootStore