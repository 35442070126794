import React, { useState } from 'react'
import RootStore from '../../../store/Root'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { TableCellActionViewVision } from '../../table/TableCellEditButton'
import { Cell } from '../../table/TableCellText.d'
import { TableCellTags } from '../../commonComponent/commonFuction'

interface Props {
  rootStore: RootStore,
}

const TableCellYesNo: React.FC<Cell> = (props) => {

    return (
        <>
            {props.value ? 'Yes' : 'No' }
        </>
    )
}

export const NumberToPriceFormatter: React.FC<Cell> = (props) => {
    const price = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.value)

    return (
        <>
            {props.value ? price : '' }
        </>
    )
}

const VisionsPage: React.FC<Props> = ({ rootStore }) => {
    const { visionStore } = rootStore

    const { searchItem, isApiError, isLoading, apiErrorMessage, fetchVisions, visionData } = visionStore
    const [searchItemField, setSearchItemField] = useState(searchItem)

    const loadPage = (page: number) => {
        fetchVisions(page, searchItem, 'change_page')
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        fetchVisions(1, e.target.value, 'search_item')
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                    
                <div className='row ml-auto'>
                    Visions
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <div className='col-auto ml-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'client_name', title: 'Probable Name' },
                                    { name: 'modified_by', title: 'Modified By' },
                                    { name: 'engagement_is_agree_and_signing', title: 'Signed', component: TableCellYesNo },
                                    { name: 'is_invoice_created', title: 'Invoice Created', component: TableCellYesNo },
                                    { name: 'invoice_price', title: 'Amount', component: NumberToPriceFormatter },
                                    { name: 'is_file_uploaded_to_lm', title: 'File uploaded to LM', component: TableCellYesNo },
                                    { name: 'user_associated_tags', title: 'Tags', component: TableCellTags },
                                    { name: 'createdAt', title: 'CreatedAt' },
                                    { name: 'updatedAt', title: 'UpdatedAt' },
                                    { name: '_id', title: 'Action', component: TableCellActionViewVision }
                                ]}
                                data={visionData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={visionData.currentPage}
                            totalPages={visionData.totalPage}
                            totalItems={visionData.totalItem}
                            itemsPerPage={visionData.itemPerPage}
                            isFiltered={visionData.isFiltered}
                            totalAllItems={visionData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(VisionsPage)