import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import Container from '../../layout/Container'
import { Breadcrumb } from '../../commonComponent/BreadcrumbComponent'
import { Dictionary, Props } from '../../../store/types.d'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import InputField, { InputFieldForPlanPricing } from '../../commonComponent/InputFieldComponent'

import SinglePlanPricingTemplateStore from '../../../store/vision/SinglePlanPricingStore'


const AddUpdatePlanPricingTemplate: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors, setValue } = useForm()

    const { planPricingTemplateStore } = rootStore
    const { isApiError, isLoading, apiErrorMessage, planDefaultPricing, planPricingTemplateDetails, resetPlanPricingTemplateDetails, addUpdateSinglePlanPricingTemplate } = planPricingTemplateStore
    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add' : 'Update'
    const pathArrayWithPluralId = history.location.pathname.split('/')

    const planPricingTemplateId = (pageName === 'Update') ? pathArrayWithPluralId[3] : ''

    useEffect(() => {
        if (pageName === 'Update') {
            planPricingTemplateStore.setPlanPricingTemplateDetails(planPricingTemplateId)
        } else if (pageName === 'Add') {
            resetPlanPricingTemplateDetails()
            planPricingTemplateStore.fetchPlanPricingForDefault()
        }
    }, [pageName, planPricingTemplateId, planPricingTemplateStore, resetPlanPricingTemplateDetails])

    const handleClick = () => {
        resetPlanPricingTemplateDetails()
        history.push('/plan-pricing-templates/')
    }

    const handleOnChangeValue = (planDetails: SinglePlanPricingTemplateStore, e: any, fieldName: string, secFieldName: string) => {
        planDetails[fieldName][secFieldName] = e.target.value
        planDetails.set(planDetails)
        setValue(e.target.name, e.target.value)
    }

    const onSubmit = async (data: Dictionary<string>) => {
        const requestData = {
            _id: planPricingTemplateId,
            name: data.name
        }

        const response = await addUpdateSinglePlanPricingTemplate(requestData)
        if (response === 'true') {
            history.push('/plan-pricing-templates/')
        }
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Plan Pricing Template', to: '/plan-pricing-templates/' },
            { name: pageName, isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName} Plan Pricing Template
            </div>
            <div className="card-body">
                {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                    {apiErrorMessage}
                </div>}

                {(planPricingTemplateId === planPricingTemplateDetails._id) ? <>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <fieldset>
                            <InputField
                                label='Name *'
                                fieldName='name'
                                register={register}
                                errors={errors.name}
                                placeholder='Enter Name'
                                defaultValue={planPricingTemplateDetails.name}
                                required={true}
                            />
                            {planDefaultPricing.length !== 0 && planDefaultPricing.map((planDetails, index) => {

                                return <React.Fragment key={planDetails._id}>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5>
                                                {planDetails.name}
                                            </h5>
                                            <hr />
                                            <div className='form-group'>
                                                <label>Price List for Single *</label>
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='form-row'>
                                                            <div className='col-md-2'>
                                                                <label>Restatement</label><br />
                                                                <label>No</label>
                                                            </div>
                                                            <InputFieldForPlanPricing
                                                                label='Initial'
                                                                fieldName={`price_for_single_${index}`}
                                                                register={register}
                                                                errors={errors[`price_for_single_${index}`]}
                                                                placeholder='Enter Initial Price'
                                                                value={planDetails.price_for_single.price}
                                                                required={true}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_single', 'price')}
                                                            />
                                                            <InputFieldForPlanPricing
                                                                label='Level-1'
                                                                fieldName={`price_for_single_level_1_${index}`}
                                                                register={register}
                                                                errors={errors[`price_for_single_level_1_${index}`]}
                                                                placeholder='Enter Level-1 Price'
                                                                value={planDetails.price_for_single.level_1_price}
                                                                required={true}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_single', 'level_1_price')}
                                                            />

                                                            <InputFieldForPlanPricing
                                                                label='Level-2'
                                                                fieldName={`price_for_single_level_2_${index}`}
                                                                register={register}
                                                                errors={errors[`price_for_single_level_2_${index}`]}
                                                                placeholder='Enter Level-2 Price'
                                                                value={planDetails.price_for_single.level_2_price}
                                                                required={true}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_single', 'level_2_price')}
                                                            />
                                                            <InputFieldForPlanPricing
                                                                label='Level-3'
                                                                fieldName={`price_for_single_level_3_${index}`}
                                                                register={register}
                                                                errors={errors[`price_for_single_level_3_${index}`]}
                                                                placeholder='Enter Level-3 Price'
                                                                value={planDetails.price_for_single.level_3_price}
                                                                required={true}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_single', 'level_3_price')}
                                                            />
                                                            <div className='w-100'></div>
                                                            <div className='col-md-2'>
                                                                <label>Yes</label>
                                                            </div>
                                                            <InputFieldForPlanPricing
                                                                label='Initial'
                                                                fieldName={`restatement_price_for_single_${index}`}
                                                                register={register}
                                                                errors={errors[`restatement_price_for_single_${index}`]}
                                                                placeholder='Enter Restatement Initial'
                                                                value={planDetails.price_for_single.restatement_price}
                                                                required={true}
                                                                needLabel={false}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_single', 'restatement_price')}
                                                            />

                                                            <InputFieldForPlanPricing
                                                                label='Level-1'
                                                                fieldName={`restatement_price_for_single_level_1_${index}`}
                                                                register={register}
                                                                errors={errors[`restatement_price_for_single_level_1_${index}`]}
                                                                placeholder='Enter Restatement Level-1'
                                                                value={planDetails.price_for_single.restatement_level_1_price}
                                                                required={true}
                                                                needLabel={false}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_single', 'restatement_level_1_price')}
                                                            />

                                                            <InputFieldForPlanPricing
                                                                label='Level-2'
                                                                fieldName={`restatement_price_for_single_level_2_${index}`}
                                                                register={register}
                                                                errors={errors[`restatement_price_for_single_level_2_${index}`]}
                                                                placeholder='Enter Restatement Level-2'
                                                                value={planDetails.price_for_single.restatement_level_2_price}
                                                                required={true}
                                                                needLabel={false}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_single', 'restatement_level_2_price')}
                                                            />
                                                            <InputFieldForPlanPricing
                                                                label='Level-3'
                                                                fieldName={`restatement_price_for_single_level_3_${index}`}
                                                                register={register}
                                                                errors={errors[`restatement_price_for_single_level_3_${index}`]}
                                                                placeholder='Enter Restatement Level-3'
                                                                value={planDetails.price_for_single.restatement_level_3_price}
                                                                required={true}
                                                                needLabel={false}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_single', 'restatement_level_3_price')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <label>Price List for Married *</label>
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='form-row'>
                                                            <div className='col-md-2'>
                                                                <label>Restatement</label><br />
                                                                <label>No</label>
                                                            </div>
                                                            <InputFieldForPlanPricing
                                                                label='Initial'
                                                                fieldName={`price_for_married_${index}`}
                                                                register={register}
                                                                errors={errors[`price_for_married_${index}`]}
                                                                placeholder='Enter Initial Price'
                                                                value={planDetails.price_for_married.price}
                                                                required={true}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_married', 'price')}
                                                            />

                                                            <InputFieldForPlanPricing
                                                                label='Level-1'
                                                                fieldName={`price_for_married_level_1_${index}`}
                                                                register={register}
                                                                errors={errors[`price_for_married_level_1_${index}`]}
                                                                placeholder='Enter Level-1 Price'
                                                                value={planDetails.price_for_married.level_1_price}
                                                                required={true}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_married', 'level_1_price')}
                                                            />

                                                            <InputFieldForPlanPricing
                                                                label='Level-2'
                                                                fieldName={`price_for_married_level_2_${index}`}
                                                                register={register}
                                                                errors={errors[`price_for_married_level_2_${index}`]}
                                                                placeholder='Enter Level-2 Price'
                                                                value={planDetails.price_for_married.level_2_price}
                                                                required={true}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_married', 'level_2_price')}
                                                            />

                                                            <InputFieldForPlanPricing
                                                                label='Level-3'
                                                                fieldName={`price_for_married_level_3_${index}`}
                                                                register={register}
                                                                errors={errors[`price_for_married_level_3_${index}`]}
                                                                placeholder='Enter Level-3 Price'
                                                                value={planDetails.price_for_married.level_3_price}
                                                                required={true}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_married', 'level_3_price')}
                                                            />
                                                            <div className='w-100'></div>
                                                            <div className='col-md-2'>
                                                                <label>Yes</label>
                                                            </div>
                                                            <InputFieldForPlanPricing
                                                                label='Initial'
                                                                fieldName={`restatement_price_for_married_${index}`}
                                                                register={register}
                                                                errors={errors[`restatement_price_for_married_${index}`]}
                                                                placeholder='Enter Restatement Initial'
                                                                value={planDetails.price_for_married.restatement_price}
                                                                required={true}
                                                                needLabel={false}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_married', 'restatement_price')}
                                                            />

                                                            <InputFieldForPlanPricing
                                                                label='Level-1'
                                                                fieldName={`restatement_price_for_married_level_1_${index}`}
                                                                register={register}
                                                                errors={errors[`restatement_price_for_married_level_1_${index}`]}
                                                                placeholder='Enter Restatement Level-1'
                                                                value={planDetails.price_for_married.restatement_level_1_price}
                                                                required={true}
                                                                needLabel={false}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_married', 'restatement_level_1_price')}
                                                            />

                                                            <InputFieldForPlanPricing
                                                                label='Level-2'
                                                                fieldName={`restatement_price_for_married_level_2_${index}`}
                                                                register={register}
                                                                errors={errors[`restatement_price_for_married_level_2_${index}`]}
                                                                placeholder='Enter Restatement Level-2'
                                                                value={planDetails.price_for_married.restatement_level_2_price}
                                                                required={true}
                                                                needLabel={false}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_married', 'restatement_level_2_price')}
                                                            />
                                                            <InputFieldForPlanPricing
                                                                label='Level-3'
                                                                fieldName={`restatement_price_for_married_level_3_${index}`}
                                                                register={register}
                                                                errors={errors[`restatement_price_for_married_level_3_${index}`]}
                                                                placeholder='Enter Restatement Level-3'
                                                                value={planDetails.price_for_married.restatement_level_3_price}
                                                                required={true}
                                                                needLabel={false}
                                                                onChange={(e) => handleOnChangeValue(planDetails, e, 'price_for_married', 'restatement_level_3_price')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </React.Fragment>
                            })}

                            <div className='form-group'>
                                <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                                    {isLoading ? 'Loading...' : planPricingTemplateId === '' ? 'Submit' : 'Update'}
                                </button>&nbsp;&nbsp;
                                <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                            </div>
                        </fieldset>
                    </form>
                </>
                    : ''}
            </div>
        </div>
    </Container>
}

export default observer(AddUpdatePlanPricingTemplate)