import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const getDeeds = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/`
    return axios.post(url, requestData)
}

export const editCompletedFlagInDeed = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/is-complete`
    return axios.post(url, requestData)
}

export const editDraftedFlagInDeed = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/is-drafted`
    return axios.post(url, requestData)
}

export const editWorkingFlagInDeed = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/is-working`
    return axios.post(url, requestData)
}

export const editPendingFlagInDeed = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/is-pending`
    return axios.post(url, requestData)
}

export const editReviewedFlagInDeed = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/deeds/deed/edit/is-reviewed`
    return axios.post(url, requestData)
}

export const getUsersEmailForDeedResearch = (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/app-users/email/deed-research`
    return axios.get(url)
}