import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../store/types.d'
import ReactEditor from '../template/ReactEditor'
import Table from '../table/Table'
import { EditStateSpecificDefaultValueButton } from '../commonComponent/StateSpecificEditButton'
import StateSpecificDefaultStore from '../../store/StateSpecificDefault'
import { initialStateSpecificDefault } from '../../store/InitialValues'
import StateSpecificValue from './StateSpecificValueModal'

const AddUpdateCommonParagraphPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { commonParagraph, stateStore } = rootStore
    const { isApiError, isLoading, apiErrorMessage, commonParagraphDetails, setCommonParagraphDetails, resetCommonParagraphDetails, addUpdateSingleCommonParagraph } = commonParagraph

    const { stateNamesAndCodesWithValueLabel } = stateStore
    const [stateDefaultValue, setStateDefaultValue] = useState<StateSpecificDefaultStore>()
    const [modalTitle, setModalTitle] = useState('')
    const [showStateDefaultForm, setShowStateDefaultForm] = useState(false)

    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add Common Paragraph' : 'Update Common Paragraph'
    const pathArrayWithStateId = history.location.pathname.split('/')
    const isUpdatePage = (pageName === 'Update Common Paragraph') ? true : false

    const commonParagraphId = (pageName === 'Update Common Paragraph') ? pathArrayWithStateId[3] : ''

    useEffect(() => {
        if (isUpdatePage && commonParagraphDetails._id !== commonParagraphId) {
            setCommonParagraphDetails(commonParagraphId)
        } else if(isUpdatePage === false) {
            resetCommonParagraphDetails()
        }

    }, [setCommonParagraphDetails, commonParagraphId, isUpdatePage, commonParagraphDetails._id, resetCommonParagraphDetails])

    const handleClick = () => {
        resetCommonParagraphDetails()
        history.push('/common-paragraphs/')
    }

    const onSubmit = async (data: Dictionary<string>) => {
        const requestData = {
            _id: commonParagraphId,
            name: data.name,
            description: data.description,
            variable_name: data.variable_name,
            value: commonParagraphDetails.value
        }
        const response = await addUpdateSingleCommonParagraph(requestData)
        if (response === 'true') {
            history.push('/common-paragraphs/')
        }
    }

    const openCloseStateDefaultModal = () => {
        setShowStateDefaultForm(!showStateDefaultForm)
    }

    const onActionClicked = (action: string, data: any) => {
        if (action === 'addStateSpecificDefault') {
            setStateDefaultValue(new StateSpecificDefaultStore(initialStateSpecificDefault))
            setModalTitle('Add State Specific Default Value')
            openCloseStateDefaultModal()
        }
        if (action === 'editStateSpecificDefault') {
            setStateDefaultValue(data)
            setModalTitle('Edit State Specific Default Value')
            openCloseStateDefaultModal()
        }
    }

    const onEditorStateChange = (data: any) => {
        commonParagraphDetails.value = data
        // setEditorState(editorState)
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Common Paragraphs', to: '/common-paragraphs/' },
            { name: (isUpdatePage) ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(commonParagraphId === commonParagraphDetails._id)
                            ? <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>

                                    <InputField
                                        label='Name *'
                                        fieldName='name'
                                        register={register}
                                        errors={errors.name}
                                        placeholder='Enter Name'
                                        defaultValue={commonParagraphDetails.name}
                                        required={true}
                                    />

                                    <div className="form-group">
                                        <label>Variable Name *</label>
                                        <input
                                            ref={register({
                                                required: 'This field is required',
                                                pattern: {
                                                    value: /^[\w_.]*$/,
                                                    message: 'This type of varible name not allow'
                                                }
                                            })}
                                            name='variable_name'
                                            type='text'
                                            defaultValue={commonParagraphDetails.variable_name}
                                            className={classNames('form-control', { 'is-invalid': errors.variable_name })}
                                            placeholder='Enter Variable Name'
                                            disabled={isUpdatePage}
                                        />
                                        {errors.variable_name && (<div className='invalid-feedback'>{errors.variable_name.message}</div>)}
                                    </div>

                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea
                                            ref={register}
                                            name='description'
                                            defaultValue={commonParagraphDetails.description}
                                            className={classNames('form-control', { 'is-invalid': errors.description })}
                                            placeholder='Enter Description'
                                        />
                                        {errors.description && (<div className='invalid-feedback'>{errors.description.message}</div>)}
                                    </div>

                                    <div className='form-group'>
                                        <ReactEditor content={commonParagraphDetails.value} label='Value' onEditorChange={onEditorStateChange} />
                                    </div>

                                    <div className='form-group'>
                                        <div>
                      State Specific Default Value
                                            <div className='card mt-2'>

                                                <div className='card-body'>
                                                    <div className='float-right mb-4'>
                                                        <button type='button' className='btn btn-sm btn-outline-primary' onClick={() => {
                                                            onActionClicked('addStateSpecificDefault', stateDefaultValue)
                                                        }}> + Add</button>
                                                    </div>
                                                    <Table
                                                        unique_key='label'
                                                        columns={[
                                                            { name: 'label', title: 'State' },
                                                            { name: '', title: 'Action', component: EditStateSpecificDefaultValueButton }
                                                        ]}
                                                        onTableAction={onActionClicked}
                                                        data={commonParagraphDetails.state_specific_values}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                        {showStateDefaultForm ? <StateSpecificValue showForm={showStateDefaultForm} targetData={stateDefaultValue} modalTitle={modalTitle} CommonParagraph={commonParagraph} openCloseForm={openCloseStateDefaultModal} options={stateNamesAndCodesWithValueLabel} /> : null}

                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdateCommonParagraphPage)