import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import Pagination from '../table/Pagination'
import { TableCellAction } from '../table/TableCellEditButton'
import { TableCellActiveInactive } from '../table/TableCellActiveInactive'
import { Link } from 'react-router-dom'
import { Cell } from '../table/TableCellText.d'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import _ from 'lodash'
interface Props {
    rootStore: RootStore,
}

const TableCellTitleCase: React.FC<Cell> = (props) => {
    const value = props.value
    return (
        <>
            {value === 'EMPLOYEE' ? 'Team-iPad' : value === 'ADMIN' ? 'Admin-iPad' : value === 'ADMIN_CMS' ? 'Team-CMS': value === 'TEAM_CMS_IPAD' ? 'Team-iPad/CMS': value === 'ADMIN_CMS_TEAM_IPAD' ? 'Admin-CMS/Team-iPad': ''}
        </>
    )
}

const ShowTags: React.FC<Cell> = (props) => {
    const value = props.value
    return (
        <>
            {_.join(value, ', ')}
        </>
    )
}

const AppUsersPage: React.FC<Props> = ({ rootStore }) => {
    const { appUserStore, authStore } = rootStore
    const { isAdmin } = authStore
    const { searchItem, isApiError, isLoading, apiErrorMessage, appUserData, searchByRoleFilter, fetchAppUser, isPageOpened } = appUserStore
    const all = { value: 'ALL', label: 'All' }
    const roleValueLabel = [
        all, {
            value: 'ADMIN',
            label: 'Admin-iPad'
        }, {
            value: 'ADMIN_CMS',
            label: 'Team-CMS'
        }, {
            value: 'EMPLOYEE',
            label: 'Team-iPad'
        }, {
            value: 'TEAM_CMS_IPAD',
            label: 'Team-iPad/CMS'
        }, {
            value: 'ADMIN_CMS_TEAM_IPAD',
            label: 'Admin-CMS/Team-ipad'
        }]

    const roleValue = roleValueLabel.find((role) => role.value === searchByRoleFilter)
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [searchFromRole, SetSearchFromRole] = useState((roleValue === undefined) ? all : roleValue)

    const loadPage = (page: number) => {
        fetchAppUser(page, searchItem, 'change_page', searchFromRole.value)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        fetchAppUser(1, e.target.value, 'search_item', searchFromRole.value)
    }

    const handleOnChangeRole = (data: any) => {
        if (data !== null) {
            SetSearchFromRole(data)
            setSearchItemField('')
            fetchAppUser(1, searchItem, 'change_filter', data.value)
        }
    }

    useEffect(() => {
        if (isAdmin && !isPageOpened) {
            fetchAppUser(1, '', '', 'ALL')
        }
    } , [isAdmin])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Users
                    <Link to='/app-users/add' className='ml-auto px-3'>Add User</Link>
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>

                            <label className='col-auto ml-auto mt-2 pr-0'>Role:</label>
                            <div className='col-sm-4'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={roleValueLabel}
                                    value={searchFromRole}
                                    handleOnChangeReact={handleOnChangeRole}
                                />
                            </div>

                            <div className='col-auto pl-0 d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>

                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'first_name', title: 'First Name' },
                                    { name: 'last_name', title: 'Last Name' },
                                    { name: 'email', title: 'Email' },
                                    { name: 'user_associated_tags', title: 'Tags', component: ShowTags },
                                    { name: 'account_code', title: 'Account Code' },
                                    { name: 'attorney_code', title: 'Attorney Code' },
                                    { name: 'role', title: 'Role', component: TableCellTitleCase },
                                    { name: 'status', title: 'Active', component: TableCellActiveInactive },
                                    { name: '_id', title: 'Action', component: TableCellAction }
                                ]}
                                data={appUserData.page_items}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={appUserData.currentPage}
                            totalPages={appUserData.totalPage}
                            totalItems={appUserData.totalItem}
                            itemsPerPage={appUserData.itemPerPage}
                            isFiltered={appUserData.isFiltered}
                            totalAllItems={appUserData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AppUsersPage)
