import React, { useEffect, useState } from 'react'
import RootStore from '../../store/Root'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import TableComp from '../table/Table'
import Pagination from '../table/Pagination'
import { Cell } from '../table/TableCellText.d'
import { Link } from 'react-router-dom'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import _ from 'lodash'
import uuid from 'react-uuid'
import { initialTemplate } from '../../store/InitialValues'
import Card from './CardViewComponent'
import classNames from 'classnames'
import { CardHeading, Table } from 'react-bootstrap-icons'
import { ValueLabel } from '../../store/types.d'

interface Props {
    rootStore: RootStore,
}

const TableCellMatterName: React.FC<Cell> = (props) => {
    const { client_id, matter_id, matter_name, document_id, linkInfo } = props.data

    return (
        <>
            <Link to={`/clients/${client_id}/matters/${matter_id}`} >{matter_name}</Link>
            { !_.isUndefined(document_id) ? 
                <>
                    <br/>
                    <Link to={linkInfo.link} className='mr-2' key={uuid()}>{linkInfo.label}</Link>
                </>
                : null }
        </>
    )
}

const TableCellCheckboxCompleted: React.FC<Cell> = (props) => {
    const value = props.value
    const defaultValue = props.data.is_completed || false

    const onChange = (e) => {
        e.preventDefault()
        if (confirm('Are you sure want to mark deed completed ?')) {
            props.data.deedResearchStore.markCompleted(value, !defaultValue)
        }
    }

    return (
        <input type='checkbox' className='align-middle' key={value + 'complete'} checked={defaultValue} onChange={onChange} />
    )
}

export const TableCellCheckboxWorking: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_working || false

    const onChange = (e) => {
        e.preventDefault()
        if (confirm('Are you sure want to mark Deed as working ?')) {
            props.data.deedResearchStore.markWorking(value, !defaultValue)
        }
    }

    return (
        <input type='checkbox' className='align-middle' key={value + 'working'} checked={defaultValue} onChange={onChange} />
    )
}

const TableCellCheckboxPending: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_pending || false
    const defaultNote = props.data.pending_notes || ''

    const onChange = (e) => {
        e.preventDefault()
        if (confirm('Are you sure want to mark Deed as pending ?')) {
            props.data.deedResearchStore.markPending(value, !defaultValue, defaultNote)
        }
    }
    const handleOnClickNote = () => {
        const notes = prompt('Add/Update Notes?', defaultNote)
        if (notes != null) {
            props.data.deedResearchStore.markPending(value, defaultValue, notes)
        }
    }

    return (
        <>
            <input type='checkbox' className='align-middle' key={value + 'pending'} checked={defaultValue} onChange={onChange} /><br />
            {defaultValue && <Link to='#' onClick={handleOnClickNote}>Notes</Link>}
        </>
    )
}

const TableCellCheckboxDrafted: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_drafted || false
    const defaultNote = props.data.drafted_notes || ''
    
    const onChange = (e) => {
        e.preventDefault()
        if (confirm('Are you sure want to mark Deed as drafted ?')) {
            props.data.deedResearchStore.markDrafted(value, !defaultValue, defaultNote)
        }
    }
    const handleOnClickNote = () => {
        const notes = prompt('Add/Update Notes?', defaultNote)

        if (notes != null) {
            props.data.deedResearchStore.markDrafted(value, defaultValue, notes)
        }
    }

    return (
        <>
            <input type='checkbox' className='align-middle' key={value + 'drafted'} checked={defaultValue} onChange={onChange} /><br />
            {defaultValue && <Link to='#' onClick={handleOnClickNote}>Notes</Link>}
        </>
    )
}

const TableCellCheckboxReviewed: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_reviewed || false
    const defaultNotes = props.data.reviewed_notes || ''

    const onChange = (e) => {
        e.preventDefault()
        if (confirm('Are you sure want to mark Deed as reviewed ?')) {
            props.data.deedResearchStore.markReviewed(value, !defaultValue, defaultNotes)
        }
    }

    const handleOnClickNote = () => {
        const notes = prompt('Add/Update reviews ?', defaultNotes)
        if (notes != null) {
            props.data.deedResearchStore.markReviewed(value, defaultValue, notes)
        }
    }

    return (
        <>
            <input type='checkbox' className='align-middle' key={value + 'reviewed'} checked={defaultValue} onChange={onChange} /><br />
            {defaultValue && <Link to='#' onClick={handleOnClickNote}>Review</Link>}
        </>
    )
}

const TableCellCompletedDetails: React.FC<Cell> = (props) => {
    // const value = props.value
    // const defaultValue = props.data.is_completed || false
    const completedBy = props.data.completed_by || ''
    const completedAt = props.data.completed_date || ''
    const details = <div>{completedBy}<br />{completedAt}</div>

    return (
        <>{details}</>
    )
}

const TableCellWorkingDetails: React.FC<Cell> = (props) => {
    // const value = props.value
    // const defaultValue = props.data.is_working || false
    const workingBy = props.data.working_by || ''
    const workingAt = props.data.working_date || ''
    const details = <div>{workingBy}<br />{workingAt}</div>

    return (
        <>{details}</>
    )
}

const TableCellReviewedDetails: React.FC<Cell> = (props) => {
    // const value = props.value
    // const defaultValue = props.data.is_reviewed || false
    const reviewedBy = props.data.reviewed_by || ''
    const reviewedAt = props.data.reviewed_date || ''
    const details = <div>{reviewedBy}<br />{reviewedAt}</div>

    return (
        <>{details}</>
    )
}

const TableCellPendingDetails: React.FC<Cell> = (props) => {
    // const value = props.value
    // const defaultValue = props.data.is_pending || false
    const pendingBy = props.data.pending_by || ''
    const pendingAt = props.data.pending_date || ''
    const details = <div>{pendingBy}<br />{pendingAt}</div>

    return (
        <>{details}</>
    )
}

const TableCellDraftedDetails: React.FC<Cell> = (props) => {
    // const value = props.value
    // const defaultValue = props.data.is_pending || false
    const draftedBy = props.data.drafted_by || ''
    const draftedAt = props.data.drafted_date || ''
    const details = <div>{draftedBy}<br />{draftedAt}</div>

    return (
        <>{details}</>
    )
}

let optionList = [{
    label: 'Completed',
    value: 'is_completed',
    isDisabled: false
}, {
    label: 'Pending',
    value: 'is_pending',
    isDisabled: false
}, {
    label: 'Drafted',
    value: 'is_drafted',
    isDisabled: false
}, {
    label: 'Review',
    value: 'is_reviewed',
    isDisabled: false
}, {
    label: 'Working',
    value: 'is_working',
    isDisabled: false
}, {
    label: 'Not Completed',
    value: 'is_not_completed',
    isDisabled: false
}, {
    label: 'Not Pending',
    value: 'is_not_pending',
    isDisabled: false
}, {
    label: 'Not Drafted',
    value: 'is_not_drafted',
    isDisabled: false
}, {
    label: 'Not Review',
    value: 'is_not_reviewed',
    isDisabled: false
}, {
    label: 'Not Working',
    value: 'is_not_working',
    isDisabled: false
}]

const TableCellPropertyDetails: React.FC<Cell> = (props) => {
    // const value = props.value
    // const defaultValue = props.data.is_completed || false
    const state = props.data.state_of_property || ''
    const county = props.data.county_of_property || ''
    const street = props.data.street_number || ''

    const details = <div>{state}<br />{county}<br />{street}</div>

    return (
        <>{details}</>
    )
}

const DeedResearchPage: React.FC<Props> = ({ rootStore }) => {
    const { deedResearchStore, clientStore, templateStore } = rootStore
    const { generateNewPDF } = clientStore
    const { templateDetails } = templateStore
    const { deedsData, tableRows, fetchDeeds, searchItem, page, userId, fetchUserEmail, userEmailWithValueLabel, filterWithFutureSigning, isTableView, setIsTableView } = deedResearchStore
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [filterWithFutureSigningValue, setFilterWithFutureSigning] = useState(filterWithFutureSigning)
    const all = { value: 'All', label: 'All' }
    const userEmailWithAll = userEmailWithValueLabel.map((state) => {
        return { value: state.value, label: state.label }
    })
    userEmailWithAll.splice(0, 0, all)
    const searchedUserId = userEmailWithAll.find((email: ValueLabel) => email.value === userId)
    const [searchByUser, setSearchByUser] = useState((searchedUserId === undefined) ? all : searchedUserId)

    useEffect(() => {
        fetchUserEmail()
        templateDetails.set(initialTemplate)
    }, [])
    const [searchStatus, setSearchStatus] = useState([])

    const loadPage = (page: number) => {
        fetchDeeds(page, searchItem, searchByUser.value, filterWithFutureSigning, searchStatus)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value)
        fetchDeeds(1, e.target.value, searchByUser.value, filterWithFutureSigning, searchStatus)
    }

    const handleFilterWithFutureSinging = (e: any) => {
        const value = e.target.checked
        setFilterWithFutureSigning(value)
        fetchDeeds(page, searchItem, searchByUser.value, value, searchStatus)
    }

    const handleOnChangeStatus = (data: any) => {
        if (data !== null) {

            if(data.length == 0) {
                optionList =  optionList.map(item => {
                    item.isDisabled = false 
                    return item
                })
            } else if(data[0].value.includes('not')) {
                optionList =  optionList.map(item => {
                    item.isDisabled = true 
                    return item
                })
            } else if(!data[0].value.includes('not')) {
                optionList = optionList.map(item => {
                    if(item.value.includes('not')) {
                        item.isDisabled = true 
                    }
                    return item
                })
            }

            setSearchStatus(data)
            fetchDeeds(1, searchItem, searchByUser.value, filterWithFutureSigning, data)
        }
    }

    const handleOnChangeUserEmail = (data: any) => {
        if (data !== null) {
            setSearchByUser(data)
            fetchDeeds(1, searchItem, data.value, filterWithFutureSigning, searchStatus)
        }
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                <div className='row ml-auto'>
                    Deeds
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div role="group" className='ml-auto btn-group view-group'>
                                <button type="button"  className={classNames({ 'active-view': isTableView === 'table_view'}, 'btn btn-outline-secondary') } data-toggle="tooltip" title='Table' onClick={() => setIsTableView('table_view')}><Table size={18}/></button>
                                <button type="button" className={classNames({ 'active-view': isTableView === 'card_view'}, 'btn btn-outline-secondary') } data-toggle="tooltip" title='Card' onClick={() => setIsTableView('card_view')}><CardHeading size={18}/></button>
                            </div>
                            <div className='col-auto ml d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField}  type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-auto ml-3 pt-2 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='filterWithNote' id="noteFilter" onClick={handleFilterWithFutureSinging} defaultChecked={filterWithFutureSigningValue} />
                                <label className="custom-control-label pr-2" htmlFor='noteFilter'>Future Signing dates only</label>
                            </div>
                            <label className='col-auto mt-2 pr-0'>Filter by Status:</label>
                            <div className='col-sm-3'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={optionList}
                                    isMulti={true}
                                    value={searchStatus}
                                    handleOnChangeReact={handleOnChangeStatus}
                                    placeholder='Select...'
                                />
                            </div>
                            <label className='col-auto mt-2 pr-0'>Filter by Market:</label>
                            <div className='col-sm-3'>
                                <ReactSelectComponent
                                    isSearchable={true}
                                    options={userEmailWithAll}
                                    value={searchByUser}
                                    handleOnChangeReact={handleOnChangeUserEmail}
                                />
                            </div>
                        </div>
                        
                        {((deedResearchStore.isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{deedResearchStore.apiErrorMessage}</div>)}
                        {((clientStore.isApiError) && <div className='responsive alert-danger p-3 mb-4 rounded' >{clientStore.apiErrorMessage}</div>)}
                        {isTableView === 'table_view' ? <div className='table-responsive table-sm'>
                            <TableComp
                                isLoading={deedResearchStore.isLoading || clientStore.isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'is_working', title: 'Working', component: TableCellCheckboxWorking },
                                    { name: 'is_pending', title: 'Pending', component: TableCellCheckboxPending },
                                    { name: 'is_drafted', title: 'Drafted', component: TableCellCheckboxDrafted },
                                    { name: 'is_reviewed', title: 'Review', component: TableCellCheckboxReviewed },
                                    { name: '_id', title: 'Completed', component: TableCellCheckboxCompleted },
                                    { name: 'matter_name', title: 'Matter Name', component: TableCellMatterName },
                                    { name: 'signing_date', title: 'Signing Date' },
                                    { name: 'pending_notes', class:'wrap-cell', title: 'Pending Notes' },
                                    { name: 'drafted_notes', class:'wrap-cell', title: 'Drafted Notes' },
                                    { name: 'reviewed_notes', class:'wrap-cell', title: 'Reviewed Notes' },
                                    { name: 'state_of_property', class:'wrap-cell', title: 'State/County/Street', component: TableCellPropertyDetails },
                                    { name: 'deed_notes', class:'wrap-cell', title: 'Deed Notes' },
                                    { name: 'grantee', class:'wrap-cell', title: 'Grantee' },
                                    { name: 'working_by', title: 'Working By/Working Date', component: TableCellWorkingDetails },
                                    { name: 'pending_by', title: 'Pending By/Pending Date', component: TableCellPendingDetails },
                                    { name: 'drafted_by', title: 'Drafted By/Drafted Date', component: TableCellDraftedDetails },
                                    { name: 'reviewed_by', title: 'Reviewed By/Reviewed Date', component: TableCellReviewedDetails },
                                    { name: 'completed_by', title: 'Completed Details', component: TableCellCompletedDetails },
                                ]}
                                data={tableRows.map((item) => {
                                    return { ...item, deedResearchStore, generateNewPDF }
                                })}
                            ></TableComp>
                        </div> :
                            <Card 
                                columnAction={[
                                    { name: 'is_working', title: 'Working:', component: TableCellCheckboxWorking },
                                    { name: 'is_pending', title: 'Pending:', component: TableCellCheckboxPending },
                                    { name: 'is_drafted', title: 'Drafted:', component: TableCellCheckboxDrafted },
                                    { name: 'is_reviewed', title: 'Review:', component: TableCellCheckboxReviewed },
                                    { name: '_id', title: 'Completed:', component: TableCellCheckboxCompleted }
                                ]}
                                actionPerformedBy={[
                                    { name: 'working_by', title: 'By:' },
                                    { name: 'pending_by', title: 'By:' },
                                    { name: 'drafted_by', title: 'By:' },
                                    { name: 'reviewed_by', title: 'By:' },
                                    { name: 'completed_by', title: 'By:' },
                                           
                                ]}
                                actionPerformedDate={[
                                    { name: 'working_date', title: 'Date:' },
                                    { name: 'pending_date', title: 'Date:' },
                                    { name: 'drafted_date', title: 'Date:' },
                                    { name: 'reviewed_date', title: 'Date:' },
                                    { name: 'completed_date', title: 'Date:' },
                                ]}

                                actionPerformedNote={[
                                    { name: 'pending_notes', title: 'Note:' },
                                    { name: 'drafted_notes', title: 'Note:' },
                                    { name: 'reviewed_notes', title: 'Note:' },
                                ]}
                                isLoading={deedResearchStore.isLoading || clientStore.isLoading}
                                unique_key='_id'
                                data={tableRows.map((item) => {
                                    return { ...item, deedResearchStore, generateNewPDF }
                                })}
                            ></Card>
                        }

                        <Pagination
                            currentPage={deedsData.currentPage}
                            totalPages={deedsData.totalPage}
                            totalItems={deedsData.totalItem}
                            itemsPerPage={deedsData.itemPerPage}
                            isFiltered={deedsData.isFiltered}
                            totalAllItems={deedsData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(DeedResearchPage)
