import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../../layout/Container'
import { useHistory } from 'react-router-dom'
import InputField from '../../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../../commonComponent/BreadcrumbComponent'
import { Props, Dictionary } from '../../../store/types.d'
import classNames from 'classnames'
import NavbarWithTags from '../NavbarWithTags'
// import _ from 'lodash'

const AddUpdatePlanPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors } = useForm()

    const { estateProtectPlanStore } = rootStore
    const { isApiError, isLoading, apiErrorMessage, estateProtectPlanDetails, addUpdateSingleEstateProtectPlan, resetEstateProtectPlanDetails, setEstateProtectPlanDetails } = estateProtectPlanStore

    const history = useHistory()
    const pageName = (history.location.pathname.includes('add')) ? 'Add Estate Protect Plan' : 'Update Estate Protect Plan'
    const pathArrayWithEstateProtectPlanId = history.location.pathname.split('/')

    const estateProtectPlanId = (pageName === 'Update Estate Protect Plan') ? pathArrayWithEstateProtectPlanId[3] : ''

    useEffect(() => {
        if (pageName === 'Update Estate Protect Plan') {
            setEstateProtectPlanDetails(estateProtectPlanId)
        } else {
            resetEstateProtectPlanDetails()
        }
    }, [])

    const handleClick = () => {
        resetEstateProtectPlanDetails()
        history.push('/estate-protect-plans/')
    }

    const onSubmit = async (data: Dictionary<string>) => {
        const requestData = {
            _id: estateProtectPlanId,
            plan_name: data.plan_name,
            display_order: data.display_order,
            plan_description: data.plan_description
        }

        const response = await addUpdateSingleEstateProtectPlan(requestData)
        if (response === 'true') {
            history.push('/estate-protect-plans/')
        }
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Estate Protect Plans', to: '/estate-protect-plans/' },
            { name: (pageName === 'Update Estate Protect Plan') ? 'Update' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(estateProtectPlanId === estateProtectPlanDetails._id)
                            ? <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>

                                    <InputField
                                        label='Plan Name *'
                                        fieldName='plan_name'
                                        register={register}
                                        errors={errors.plan_name}
                                        placeholder='Enter Plan Name'
                                        defaultValue={estateProtectPlanDetails.plan_name}
                                        required={true}
                                    />

                                    <div className="form-group">
                                        <label>Display Order</label>
                                        <input
                                            ref={register({
                                                required: 'This field is required'
                                            })}
                                            name='display_order'
                                            type='number'
                                            min={1}
                                            className={classNames('form-control', { 'is-invalid': errors.display_order })}
                                            defaultValue={estateProtectPlanDetails.display_order}
                                        />
                                        {errors.display_order && (<div className='invalid-feedback'>{errors.display_order.message}</div>)}
                                    </div>

                                    <div className='form-group'>
                                        <label>Description</label>
                                        <textarea
                                            ref={register}
                                            name='plan_description'
                                            defaultValue={estateProtectPlanDetails.plan_description}
                                            className={classNames('form-control', { 'is-invalid': errors.plan_description })}
                                            placeholder='Enter Description'
                                        />
                                        {errors.plan_description && (<div className='invalid-feedback'>{errors.plan_description.message}</div>)}
                                    </div>

                                    <div className='form-group'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>
                                            {isLoading ? 'Loading...' : estateProtectPlanId === '' ? 'Submit': 'Update'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdatePlanPage)