import React, { useState } from 'react'
import RootStore from '../../store/Root'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { addState } from '../../api/StateActions'
import InputField from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'

interface Props {
  rootStore: RootStore,
}

interface Dictionary<T> {
  // eslint-disable-next-line
  [Key: string]: T | any
}

const AddStatePage: React.FC<Props> = ({ rootStore }) => {
    const [apiErrorMessage, setApiErrorMessage] = useState('')
    const [isApiError, setIsApiError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { fetchState } = rootStore.stateStore
    const { register, handleSubmit, errors } = useForm()
    const history = useHistory()

    const handleClick = () => {
        history.push('/states/')
    }

    const onSubmit = (data: Dictionary<string>) => {

        setIsLoading(true)
        const requestData = {
            state_name: data.state_name,
            state_code: data.state_code
        }

        addState(requestData).then((response) => {
            setIsLoading(false)

            if (response.data.success === 0) {
                setIsApiError(true)
                setApiErrorMessage(response.data.message)
            } else {
                history.push('/states/')
                fetchState(1, '', 'add')
            }

        }).catch((e) => {
            setIsLoading(false)
            setIsApiError(true)
            setApiErrorMessage(e.message)
        })
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'States', to: '/states/' },
            { name: 'Add', isActive: true }
        ]} />
    
        <div className="card">
            <div className="card-header">
        Add State
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <fieldset>

                                <InputField
                                    label='State Name *'
                                    fieldName='state_name'
                                    register={register}
                                    errors={errors.state_name}
                                    placeholder='Enter State Name'
                                    required={true}
                                />

                                <InputField
                                    label='State Code *'
                                    fieldName='state_code'
                                    register={register}
                                    errors={errors.state_code}
                                    placeholder='Enter State Code'
                                    required={true}
                                />

                                <div className='form-group'>
                                    <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </button>&nbsp;&nbsp;
                                    <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddStatePage)